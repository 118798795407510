import './polyfill';
import { PrivyProvider } from '@privy-io/react-auth';
import { RouterProvider } from 'react-router-dom';
import OneSignal from 'react-onesignal';
import { router } from './router';
import { env } from './config/env';
import { useEffect } from 'react';
import { PrivyConnect, privyConfig } from './components/PrivyConnect/PrivyConnect';
import { providers } from './lib/providers';

import { controller } from 'lib/Controller';
import { Modals } from 'features/Modals';

import './styles/globals.scss';
import './styles/_variables.scss';
import './styles/app.scss';

// Setup any providers we have
Object.values(providers).forEach((initProvider) => initProvider());

function App() {
  // How can logs called in the children can happen before this?
  // This should be on component mount which in theory happens before on component draw, no?
  // Response (jb): This is because useEffects are not done on component mount, but one frame after the first render.
  // In legacy class components, this would almost be the equivalent of calling `componentDidMount` instead of `componentWillMount`.
  useEffect(() => {
    console.log('App Load (Timestamp): ', new Date().toLocaleTimeString(undefined, window.gzTimeFormat));

    // automatically open GetPoints modal on app start
    // todo: skip this if no points were earned between sessions
    // todo(JB): we also will want to delay displaying any toast notifications until user has closed this modal and points animation has ended
    /*if (controller.isPointsFeatureEnabled && controller.isAuth) {
      controller.modals.open(Modals.GetPoints);
    }*/

    OneSignal.init({ appId: env.oneSignalAppId });
  }, []);

  return (
    <PrivyProvider appId={env.privyAppId} config={privyConfig}>
      {/* Connect Privy to a controller */}
      <PrivyConnect />
      {/* Used to calculate the safe areas on mobile */}
      <div id="game-safe-areas">
        <div id="game-safe-area-top"></div>
        <div id="game-safe-area-bottom"></div>
      </div>
      {/* App content via router */}
      <RouterProvider router={router} />
    </PrivyProvider>
  );
}

export default App;

/*
interface RouteObject {
  path?: string;
  index?: boolean;
  children?: React.ReactNode;
  caseSensitive?: boolean;
  id?: string;
  loader?: LoaderFunction;
  action?: ActionFunction;
  element?: React.ReactNode | null;
  hydrateFallbackElement?: React.ReactNode | null;
  errorElement?: React.ReactNode | null;
  Component?: React.ComponentType | null;
  HydrateFallback?: React.ComponentType | null;
  ErrorBoundary?: React.ComponentType | null;
  handle?: RouteObject["handle"];
  shouldRevalidate?: ShouldRevalidateFunction;
  lazy?: LazyRouteFunction<RouteObject>;
}
 */
