import { images } from 'assets/assets';
import { TopBarCurrency } from './TopBarCurrency';
import { Modals } from 'features/Modals';
import { useReRender } from 'hooks/useReRender';
import { controller } from 'lib/Controller';

export function TopBarPointCurrency() {
  const { visible, setComponent, state } = controller.ui.elements.TopBarPointCurrency;
  const { pointsBalance = 0 } = controller.user.me || {};

  useReRender({ id: 'TopBarPointCurrency', listener: setComponent });

  const onClick = () => {
    // avoid interaction/navigation if any modal is open
    if (controller.modals.current?.id !== undefined) return;

    controller.modals.open(Modals.Error, {
      title: `Play. Make. Earn.`,
      message: '• Play games to win points\n• Make and share games\n• Earn points buying and selling items',
      btnText: 'ok',
    });
  };

  return (
    <TopBarCurrency
      type="point"
      onClick={onClick}
      show={visible}
      icon={images.iconPoints}
      fakeBalance={state.displayBalance}
      userBalance={Number(pointsBalance)}
    />
  );
}
