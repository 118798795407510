import { EventListener } from 'lib/EventListener';
import { FeatureCache } from '../cache/FeatureCache';
import { getDeviceMode } from '../device';
import { UIElementController } from './UIElementController';
import { controller } from 'lib/Controller';
import { Routes } from 'router';

export enum UIElementKey {
  DebugComponent = 'DebugComponent',
  TopBar = 'TopBar',
  TopBarCoinCurrency = 'TopBarCoinCurrency',
  TopBarPointCurrency = 'TopBarPointCurrency',
  TopBarDiamondCurrency = 'TopBarDiamondCurrency',
  TopBarCurrency = 'TopBarCurrency',
  TopBarSearch = 'TopBarSearch',
  BottomNavbar = 'BottomNavbar',
  CommentSidebar = 'CommentSidebar',

  CurrencyAnimationEffect = 'CurrencyAnimationEffect',
}

export enum UIControllerEvent {
  Change = 'UIControllerEvent/Change',
  DocumentSchemeChange = 'UIControllerEvent/DocumentSchemeChange',
  ShowDesktopInboxChange = 'UIControllerEvent/ShowDesktopInboxChange',
}

export interface UICache {
  scheme: AppUIScheme;
}

interface AppUIScheme {
  layout: 'desktop' | 'mobile';
  color: 'dark' | 'light';
}

export class UIController extends EventListener {
  private uiCache = new FeatureCache<UICache>('uiCache', (cache) => ({
    scheme: {
      layout: cache.scheme?.layout || 'desktop',
      color: cache.scheme?.color || 'dark',
    },
  }));

  // Register here all the ui elements we have, schema is optional, used if the component needs global ui state
  private _elements = {
    [UIElementKey.DebugComponent]: new UIElementController(UIElementKey.DebugComponent),
    [UIElementKey.TopBar]: new UIElementController(UIElementKey.TopBar),
    [UIElementKey.TopBarCurrency]: new UIElementController(UIElementKey.TopBarCurrency, TopBarCurrencyInitState),
    [UIElementKey.TopBarCoinCurrency]: new UIElementController(UIElementKey.TopBarCoinCurrency, TopBarCurrencyInitState),
    [UIElementKey.TopBarPointCurrency]: new UIElementController(UIElementKey.TopBarPointCurrency, TopBarCurrencyInitState),
    [UIElementKey.TopBarDiamondCurrency]: new UIElementController(UIElementKey.TopBarDiamondCurrency, TopBarCurrencyInitState),
    [UIElementKey.TopBarSearch]: new UIElementController(UIElementKey.TopBarSearch),
    [UIElementKey.BottomNavbar]: new UIElementController(UIElementKey.BottomNavbar),
    [UIElementKey.CommentSidebar]: new UIElementController(UIElementKey.CommentSidebar),

    [UIElementKey.CurrencyAnimationEffect]: new UIElementController(UIElementKey.CurrencyAnimationEffect),
  };

  private uiScheme = this.uiCache.get('scheme');

  get scheme() {
    return this.uiScheme;
  }

  get elements() {
    return this._elements;
  }

  get isDesktopLayout() {
    return this.uiScheme.layout === 'desktop';
  }

  get isMobileLayout() {
    return !this.isDesktopLayout;
  }

  get isAiAssistant() {
    return controller.currentRoute === Routes.Create;
  }

  get isDesktopAiAssistant() {
    return this.isDesktopLayout && controller.currentRoute === Routes.Create;
  }

  get isDesktopGameFeed() {
    return this.isDesktopLayout && controller.currentRoute === Routes.GameFeed;
  }

  get isGameFullScreen() {
    return this.isDesktopAiAssistant;
  }

  private _showCurrencyAnimation = false;
  get showCurrencyAnimation() {
    return this._showCurrencyAnimation;
  }

  private _showDesktopWindowInbox = false;
  get showDesktopWindowInbox() {
    return this._showDesktopWindowInbox;
  }

  constructor() {
    super();
    // UIController will be alive for the whole session, no need to remove the listener
    window.addEventListener('resize', this.onWindowResize);
    this.updateDocumentScheme();
    this.events = {
      on_change: [],
    };
    this.onWindowResize();
  }

  private onWindowResize = () => {
    this.uiScheme.layout = getDeviceMode();
    // console.warn('>>> resizing app to', `${window.visualViewport?.width} x ${window.visualViewport?.height}`, getDeviceMode());
    this.updateDocumentScheme();
  };

  private updateDocumentScheme = () => {
    // change app css
    document.documentElement.className = `${this.uiScheme.layout} ${this.uiScheme.color}`;
    this.uiCache.set('scheme', this.uiScheme);
    this.sendEvents([UIControllerEvent.Change, UIControllerEvent.DocumentSchemeChange]);
  };

  public setColorScheme = (color: 'dark' | 'light') => {
    this.uiScheme.color = color;
    this.updateDocumentScheme();
  };

  public setShowDesktopWindowInbox(value: boolean) {
    this._showDesktopWindowInbox = value;
    this.sendEvents([UIControllerEvent.Change, UIControllerEvent.ShowDesktopInboxChange]);
  }

  public addOptimisticCoins(amount: number) {
    const coinCurrency = this.elements[UIElementKey.TopBarCoinCurrency];
    coinCurrency.updateState({ displayBalance: coinCurrency.state.displayBalance + amount });
  }
}

const TopBarCurrencyInitState = {
  displayBalance: 0,
};
