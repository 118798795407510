import { ClientJS } from 'clientjs';
import { Fingerprint } from '@storyverseco/svs-types';
import { FeatureCache } from 'lib/cache/FeatureCache';
import { api } from 'lib/apis';
import { AppController } from 'lib/Controller';
import { Game } from 'lib/Game';
import { EventListener } from 'lib/EventListener';
import { isReactSnap } from 'config/consts';
import { ConditionWaiter } from '../ConditionWaiter';

export interface FingerprintCache {
  fingerprint: Fingerprint;
  userIds: number[];
}

export class FingerprintController extends EventListener {
  // Generates a fingerprint for the device
  readonly _deviceFingerprintId: number = new ClientJS().getFingerprint();

  private _id = this._deviceFingerprintId;

  private enabled = !isReactSnap;

  get id() {
    return this._id;
  }

  private cache = new FeatureCache<FingerprintCache>('fingerprintCache', () => undefined);

  private get fp() {
    return this.cache.get('fingerprint');
  }

  public get userId() {
    return this.fp?.userId;
  }

  private get attributes() {
    return this.fp?.attributes;
  }

  private get userIds() {
    return this.cache.get('userIds') || [];
  }

  public get gameId() {
    return this.attributes?.initialGameId;
  }

  private readyWaiter = new ConditionWaiter(false);
  public get waitForReady() {
    return this.readyWaiter.wait();
  }

  constructor(private app: AppController) {
    super();
    this.init();
  }

  private init = async () => {
    if (!this.enabled) {
      this.readyWaiter.set(true);
      return;
    }

    await this.cache.isReady;

    // This should never happen. If it ever does we will need to know
    // e.g it happens if you switch between chrome profiles, using separate profile for every user
    // clean your site data and refresh the page
    if (this.fp && this.fp.id !== this._id) {
      // Not sure what to do in this case, just assume cache id as the correct one
      this.error(`Cached fingerprint '${this.fp.id}' does not match current fingerprint '${this._id}'`);
      this._id = this.fp.id;
    }

    if (this.fp && this.fp.userId) {
      await this.persistSession();
    }

    // As soon as we have worked out the id, resolve promise, the rest can be done "async"
    this.readyWaiter.set(true);

    // If we have no cached fingerprint, check remote
    if (!this.fp) {
      // Find a remote fingerprint which hasn't been used
      const remoteFingerprint = await api.user.fingerprint.get(this._id);

      if (!remoteFingerprint?.attributes?.initialGameId) {
        await this.createRemoteFingerprint();
      } else {
        this.syncWithRemote(remoteFingerprint);
      }
    }

    this.log('fingerprint', ['init complete', { fp: this.fp }]);
  };

  private _persistedSessionRoute: string;
  get persistedSessionRoute() {
    return this._persistedSessionRoute;
  }
  private persistSession = async () => {
    const path = window.location.pathname;
    const searchParams = window.location.search;
    const fullPathWithParams = `${path}${searchParams}`;

    const currentTimeStamp = await api.user.fingerprint.persistSession({
      fingerprintId: this._id,
      userId: this.userId,
      sessionData: {
        browserType: this.app.browserType,
        route: fullPathWithParams,
      },
    });

    // in pwa, we check the session of mobile browser
    // and if the session was less then 2 sec ago, we assume that user open pwa from notification
    // and we redirect him to his last page
    // route format is relative url with search params, like '/game/:gameId?aaa=111'
    if (this.app.browserType === 'pwa') {
      const session = this.fp.attributes?.mobileWeb;
      const { route, updatedAt } = session || {};
      if (route && currentTimeStamp - updatedAt < 2000) {
        this._persistedSessionRoute = route;
      }
    }
  };

  private addUserIdToList = (userId: number) => {
    const ensureNumberUserId = Number(userId);
    // Skip adding duplicates
    if (this.userIds.includes(ensureNumberUserId)) {
      return;
    }
    this.userIds.push(ensureNumberUserId);

    this.cache.set('userIds', this.userIds);
    this.cache.save();
  };

  private syncWithRemote = (remoteFp: Fingerprint) => {
    this.log('fingerprint', ['syncWithRemote', { remoteFp }]);
    this.cache.set('fingerprint', remoteFp);
    if (remoteFp.userId) {
      this.addUserIdToList(remoteFp.userId);
    }
    this.cache.save();
  };

  private waitForCurrentFeedItem = async (): Promise<Game> => {
    const currentFeedItem = this.app.feed.currentItem;
    if (currentFeedItem) {
      return Promise.resolve(currentFeedItem);
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve(this.waitForCurrentFeedItem());
      }, 200);
    });
  };

  private createRemoteFingerprint = async () => {
    const currentFeedItem = await this.waitForCurrentFeedItem();

    if (currentFeedItem) {
      await api.user.fingerprint.create(this._id, currentFeedItem.id);
      const remoteFingerprint = await api.user.fingerprint.get(this._deviceFingerprintId);
      this.syncWithRemote(remoteFingerprint);
      return;
    }

    throw new Error(`Trying to create fingerprint without 'currentFeedItem'.`);
  };

  public setUserId = async (userId: number) => {
    if (!this.enabled) {
      return;
    }

    const ensureNumberUserId = Number(userId);
    const isNewUserId = !this.userIds.includes(ensureNumberUserId);

    // If we don't have a userId set by now, then assign one to remote
    if (isNewUserId) {
      try {
        await api.user.fingerprint.assign(this._id);
      } catch (e) {
        this.error(`FingerprintHandler (setUserId):`, [e]);
        // fail silently
      }
      this.addUserIdToList(userId);
    } else {
      // fetch user remote data
      let remoteFingerprint = await api.user.fingerprint.get(this._id, ensureNumberUserId);
      if (!remoteFingerprint) {
        console.warn(`Trying to retrieve fingerprint for '${ensureNumberUserId}'. Expected to exist, but not found.`);
        await api.user.fingerprint.assign(this._id);
        remoteFingerprint = await api.user.fingerprint.get(this._id, ensureNumberUserId);
      }
      this.syncWithRemote(remoteFingerprint);
    }

    this.cache.set('fingerprint', {
      ...this.cache.get('fingerprint'),
      userId: ensureNumberUserId,
    });

    this.cache.save();
  };
}
