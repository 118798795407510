import { WalletAddress } from '@storyverseco/svs-types';
import { formatEther } from 'viem';

/**
 * @param address 0x000000000000000000
 * @returns 0x0000...00000
 */
export const getEllipsisAddress = (address: WalletAddress | string) =>
  `${address?.substring(0, 6)}...${address?.substring(address?.length - 5, address?.length)}`;

const tokenRegex = /\{\{\s*([a-zA-Z0-9_]+)\s*\}\}/g;
/**
 * Replace `{{token}}` by token map
 */
export const tokenReplace = ({ text, tokenMap, strict = false }: { text: string; tokenMap: Record<string, string>; strict?: boolean }) => {
  return text.replace(tokenRegex, (_substring, p1) => {
    if (p1 && p1 in tokenMap && typeof tokenMap[p1] === 'string') {
      return tokenMap[p1];
    }
    // if no match and strict, throw error
    if (strict) {
      throw new Error(`tokenReplace: Mising "${p1}" string token`);
    }
    // if no match, replace with empty string
    return '';
  });
};

export const pluralize = (count: number, title: string, pluralTitle?: string) => {
  return count === 1 ? `${count} ${title}` : `${count} ${pluralTitle || title + 's'}`;
};

export const pluralizeWordOnly = (count: number, title: string, pluralTitle?: string) => {
  return count === 1 ? title : pluralTitle || `${title}s`;
};

export const simplifyNamesToString = (names: string[], maxVisible = 2): string => {
  // a
  if (names.length === 1) {
    return names[0];
  }
  // a and b
  if (names.length === 2 && maxVisible >= 2) {
    return names.join(' and ');
  }
  // a, b, and c
  if (names.length <= maxVisible && names.length >= 3) {
    return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
  }
  // a, b, and n other(s)
  return `${names.slice(0, maxVisible).join(', ')} and ${pluralize(names.length - maxVisible, 'other')}`;
};

export const ellipsisize = (text: string, maxWords: number, maxLength: number): string => {
  let add = false;
  if (text.length > maxLength) {
    text = text.substring(0, maxLength);
    add = true;
  }

  const words = text.split(' ');
  if (words.length > maxWords) {
    // if ellipses are already being added, then chances are the last word is cut-off
    if (add) {
      text = words.slice(0, maxWords - 1).join(' ');
    } else {
      text = words.slice(0, maxWords).join(' ');
    }
    add = true;
  }

  if (add) {
    return text + '...';
  }

  return text;
};

export const getPrettyPrice = (price: string | number) => {
  return Number(formatEther(BigInt(+price))).toFixed(4);
};

export function formatBuyPrice(amount: number | string, gemType: 'coin' | 'diamond' | 'gemz' | 'eth' | 'craftable'): string {
  if (amount == null) return '';

  if (gemType === 'coin') {
    return formatCoinBuyPrice(amount);
  }
  if (gemType === 'eth' || gemType === 'diamond' || gemType === 'gemz') {
    return formatEthBuyPrice(amount);
  }
  return '';
}

//Note, should not be used by craftable since its sell price can be multiple currencies
export function formatSellPrice(amount: number | string, gemType: 'coin' | 'diamond' | 'gemz' | 'eth' | 'craftable'): string {
  if (amount == null) return '';

  if (gemType === 'coin' || gemType === 'craftable') {
    return formatCoinSellPrice(amount);
  }
  if (gemType === 'eth' || gemType === 'diamond' || gemType === 'gemz') {
    return formatEthSellPrice(amount);
  }
  return '';
}

export function formatCoinBuyPrice(amount: number | string): string {
  const formattedAmmount = formatToRoundedString(amount, 'ceil', 0);
  return formattedAmmount === '0' ? 'Free' : formattedAmmount;
}

export function formatCoinSellPrice(amount: number | string): string {
  return formatToRoundedString(amount, 'floor', 0);
}

export function formatCoinAccountBalance(amount: number | string): string {
  return formatToRoundedString(amount, 'floor', 0);
}

export function formatEthBuyPrice(amount: number | string): string {
  const numericAmount = typeof amount === 'string' ? Number(amount) : amount;
  if (numericAmount <= 0.001) return '0.001'; // Minimum display price for eth

  const ethAmount = formatEther(BigInt(+amount));
  return formatToRoundedString(ethAmount, 'ceil', 3);
}

export function formatEthSellPrice(amount: number | string): string {
  const ethAmount = formatEther(BigInt(+amount));
  return formatToRoundedString(ethAmount, 'floor', 3);
}

export function formatEthAccountBalance(amount: number | string): string {
  return formatToRoundedString(amount, 'floor', 5);
}

export function formatToRoundedString(amount: number | string, roundMode: 'floor' | 'ceil' | 'round' = 'round', maxDigits: number = 3): string {
  // Convert amount to a number if it is a string
  const numericAmount = typeof amount === 'string' ? Number(amount) : amount;
  if (numericAmount == 0) return '0';

  // Adjust numericAmount by adding Number.EPSILON before rounding
  const adjustedAmount = numericAmount + Number.EPSILON;
  // Calculate the scaling factor based on the number of digits
  const scaleFactor = Math.pow(10, maxDigits);

  let roundedAmount;
  // Apply the rounding method based on roundMode parameter
  if (roundMode === 'floor') {
    roundedAmount = Math.floor(adjustedAmount * scaleFactor) / scaleFactor;
  } else if (roundMode === 'ceil') {
    roundedAmount = Math.ceil(adjustedAmount * scaleFactor) / scaleFactor;
  } else {
    roundedAmount = Math.round(adjustedAmount * scaleFactor) / scaleFactor;
  }

  // Check if the roundedAmount is falsy (0, null, undefined, NaN, '', false) and return '0' or return the rounded, fixed decimal string
  return roundedAmount ? roundedAmount.toFixed(maxDigits) : '0';
}

export function getNotificationFormattedPrice(price: number, gemType: 'coin' | 'diamond'): string {
  if (price == null) return '';

  if (gemType === 'coin') {
    return `${formatCoinBuyPrice(price)} coins`;
  }
  if (gemType === 'diamond') {
    return `${formatEthBuyPrice(price)} ETH`;
  }
  return '';
}

export function getNotificationFormattedReward(price: number, gemType: 'coin' | 'diamond'): string {
  if (!price) return '';

  if (gemType === 'coin') {
    return `${formatCoinAccountBalance(price)} coins`;
  }
  if (gemType === 'diamond') {
    return `${getPrettyPrice(price)} ETH`;
  }
  return '';
}
