import './CurrencyAnimationEffect.scss';
import { useRef } from 'react';
import TweenOne from 'rc-tween-one';
import { controller } from 'lib/Controller';
import { GemType } from '@storyverseco/svs-types';
import { getElementPointById } from 'lib/domUtils';
import { getRandomizedPointWithinRadius, findQuadraticBezierCurve } from 'lib/mathUtils';
import Rive from '@rive-app/react-canvas-lite';
import { getAssetType, getCurrencyAnimationAsset } from 'lib/assetUtils';

export interface CurrencyAnimationEffectOpts {
  targetPoint?: { x: number; y: number };
  currencyType?: GemType | 'point';
  targetPrice?: number;
  isShowingMultiple?: boolean;
  onComplete?: () => void;
}

type Point = { x: number; y: number };

const CurrencyAnimationEffect = () => {
  let { targetPoint, currencyType, targetPrice, isShowingMultiple = false, onComplete = () => {} } = controller.fx.opts;

  const isTargetPriceInvalid = Number.isNaN(targetPrice) || targetPrice === 0;
  if (isTargetPriceInvalid) return null;

  const topBarPoint = getElementPointById(`topbar-currency-${currencyType}`, true, true);
  const isMissingPoint = topBarPoint == null || targetPoint == null;
  if (isMissingPoint) return null;

  const isPositivePrice: boolean = targetPrice > 0;
  const labelPrice = isPositivePrice ? '+' + targetPrice : targetPrice;
  const tweenDirection = isPositivePrice ? 'forward' : 'reverse';

  const startPosition = isPositivePrice ? targetPoint : topBarPoint;
  const endPosition = isPositivePrice ? topBarPoint : targetPoint;
  const labelPosition = targetPoint;

  const minItemCount = 1;
  const maxItemCount = isShowingMultiple ? 5 : 1;
  const currencyItemCount = isPositivePrice ? Math.max(minItemCount, Math.min(maxItemCount, targetPrice)) : 0;
  const currencyItems = Array.from({ length: currencyItemCount }, (_, index) => index + 1);
  const isRandomizedStart = currencyItemCount > 1;
  const speed = currencyItemCount > 1 ? 1.0 : 1.5;

  const labelItemCount = 1;
  itemCount = currencyItemCount + labelItemCount;
  itemsCompletedCount = 0;

  return (
    <div className="currency-animation-effect">
      {currencyItems.map((item, index) => (
        <Item
          key={index}
          tweenDirection={tweenDirection}
          index={index}
          start={startPosition}
          end={endPosition}
          currencyType={currencyType}
          speed={speed}
          isRandomizedStart={isRandomizedStart}
          onComplete={onComplete}
        />
      ))}

      <TweenOne
        className="currency-label"
        style={{
          transform: `translate(${labelPosition.x}px, ${labelPosition.y}px)`,
          opacity: 1,
        }}
        animation={{
          x: labelPosition.x,
          y: labelPosition.y - 100,
          opacity: 0,
          type: 'to',
          ease: 'easeInOutSine',
          duration: 1500 * speed,
          onComplete: () => {
            onItemCompleted(onComplete);
          },
        }}
      >
        <div>{labelPrice}</div>
      </TweenOne>
      <div className="currency-animation-point start" style={{ left: startPosition.x, top: startPosition.y }}></div>
      <div className="currency-animation-point end" style={{ left: endPosition.x, top: endPosition.y }}></div>
    </div>
  );
};

export default CurrencyAnimationEffect;

// render each animated currency item

const Item = (opts: {
  index: number;
  tweenDirection: string;
  start: Point;
  end: Point;
  currencyType: GemType | 'point';
  speed: number;
  isRandomizedStart: boolean;
  onComplete: () => void;
}) => {
  const itemRef = useRef<HTMLDivElement | undefined>();
  const iconRef = useRef<HTMLDivElement | undefined>();

  const { index, tweenDirection, start, end, currencyType, speed, isRandomizedStart } = opts;

  const radius = 15;
  const randomizedStart = isRandomizedStart ? getRandomizedPointWithinRadius(start, radius) : start;

  const bezierPoints = findQuadraticBezierCurve(randomizedStart, end, 0.15);

  const animationAsset = getCurrencyAnimationAsset(currencyType);
  const assetType = getAssetType(animationAsset);
  const backgroundStyle = assetType !== 'riv' ? { backgroundImage: `url(${animationAsset})` } : {};

  const duration = (750 + Math.random() * 150) * speed;
  const delay = index * 250 * speed;

  const scale = 1.0;

  const scaleStart = tweenDirection === 'forward' ? scale : scale * 0.5;
  const scaleEnd = tweenDirection === 'forward' ? scale * 0.5 : scale;

  const onComplete = opts.onComplete;

  return (
    <>
      <TweenOne
        ref={itemRef}
        className="currency-item"
        style={{
          transform: `translate(${randomizedStart.x}px, ${randomizedStart.y}px)`,
          display: 'default',
        }}
        animation={{
          PathMotion: {
            path: bezierPoints,
            pathVars: {
              type: 'soft',
              curviness: 1,
            },
            rotate: false,
          },
          duration: duration,
          delay: delay,
          onStart: () => {},
          onUpdate: () => {},
          onComplete: () => {
            itemRef?.current?.setAttribute('style', 'display: none;');
            onItemCompleted(onComplete);
          },
        }}
      >
        <TweenOne
          ref={iconRef}
          className="currency-item-icon"
          style={{
            transform: `scale(${scaleStart})`,
            visibility: 'hidden',
            ...backgroundStyle,
          }}
          animation={{
            scale: scaleEnd,
            duration: duration,
            delay: delay,

            onStart: () => {
              (iconRef.current as HTMLDivElement).style.visibility = 'visible';
            },
          }}
        >
          {assetType === 'riv' && <Rive src={animationAsset} />}
        </TweenOne>
      </TweenOne>
    </>
  );
};

let itemCount = 0;
let itemsCompletedCount = 0;
const onItemCompleted = (onComplete: () => void) => {
  itemsCompletedCount += 1;
  const isAllItemsCompleted = itemsCompletedCount >= itemCount;
  if (isAllItemsCompleted) {
    onComplete?.();
  }
};
