/*
* @readonly This file is generated dynamically. Change 'envs/{env}.ts' instead.
*/
import { baseSepolia, sepolia } from "@alchemy/aa-core";
import { Env } from "@storyverseco/svs-types";
import { BASE_SEPOLIA_ENTRYPOINT_ADDRESS } from "config/consts";

export const env = {
  l1Chain: sepolia,
  l2Chain: baseSepolia as any,
  assetsApiPrefix: '',
  authBaseUrl: 'https://auth.beta.pnk.one',
  chainIds: [5] as number[],
  env: 'dev' as Env,
  foundersPassContract: '',
  gameUrl: 'https://view.dev.gemz.pnk.one?iframed=true&logLevel=off',
  mbaasApiKey: '',
  mbaasBaseUrl: '',
  contractName: 'gemz_lazy_dev',
  mediaServiceBaseUrl: '',
  persistPlotServerPrefix: '',
  persistStoryServerPrefix: '',
  pipelineBaseUrl: 'https://pipeline.beta.pnk.one',
  plotsBaseURI: '',
  serviceProxyUrl: '',
  templateApiPrefix: '',
  walletConnectId: '',
  walletConnectorVersion: 1 as 1 | 2,
  privyAppId: 'clmrtr23g02opl90fq0x2trrd',
  // oneSignalAppId: '92e6efe3-a213-43ae-bf7a-05c374296b37', // -> yulia ngrock
  // oneSignalAppId: 'a21cc4e2-bc89-468d-aae0-67b62badf0a9', // -> jb ngrok
  oneSignalAppId: 'b756c1e8-f52d-489d-be12-88a166972eb8', // -> pnk.one
  urls: {
    rpc: {
      alchemy: 'https://base-sepolia.g.alchemy.com/v2/Tx_Qwmy8UD7ZCTKoQt0rOeBpFdKiEx_2',
      coinbase: 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/_lIXH-xvn2qABZn1z1qmdim09LbrNJ8n',
    }
  },
  entryPointAddress: BASE_SEPOLIA_ENTRYPOINT_ADDRESS,
}

