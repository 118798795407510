import { ActivityFeedItem, ActivityItem, ActivityType, activityTypes } from 'features/Notifications/types';
import { AppController } from 'lib/Controller';
import { EventListener } from 'lib/EventListener';
import { api } from 'lib/apis';
import { daysToMs, secondsToMs } from 'lib/time';
import isEqual from 'lodash.isequal';

interface RestartOpts {
  pollInterval?: number;
  updateNow?: boolean;
}

const DEFAULT_POLL_INTERVAL = secondsToMs(10);

// @TODO(jb): re-enable when we have updated the grouped pfp style and have a page to see the list
// const groupableByGameType = [ActivityType.LikedGame, ActivityType.Buy, ActivityType.Sell];
const groupableByGameType = [];

// @TODO(jb): re-enable when we have updated the grouped pfp style and have a page to see the list
// const groupableByProfileType = [ActivityType.Visit, ActivityType.Follow, ActivityType.FollowBack];
const groupableByProfileType = [];

// @TODO: Should we have a global var here?
let groupIdPool = 0;

const GROUP_TIME_THRESHOLD = daysToMs(7);

const MIN_GROUP_ITEMS = 4;

const createGroupId = (groupType: string, subgroup: string, time?: number) => `group-${groupType}-${subgroup}-${groupIdPool++}-${time || Date.now()}`;

export enum ActivityFeedState {
  Idle = 'idle',
  FirstLoading = 'firstLoading',
  Loading = 'loading',
  Loaded = 'loaded',
  Errored = 'errored',
}

export enum NotificationsEvent {
  FeedUpdate = 'NotificationsEvent/FeedUpdate',
  UnreadChange = 'NotificationsEvent/UnreadChange',
}

export class NotificationsController extends EventListener {
  private pollId: NodeJS.Timer = null;
  private pollInterval = DEFAULT_POLL_INTERVAL;
  private userId?: number;

  private rawFeed: ActivityItem[] = [];
  private feed: ActivityItem[] = [];
  private feedState: ActivityFeedState = ActivityFeedState.Idle;
  private unreadMap: Record<string, boolean> = {};
  private _unreadNonce = 0;
  private activityToGroupMap: Record<string, string> = {};
  // @TODO: Couldnt find the usage of this, if we do need, re-introduce it
  // private error: Error | undefined | null = null; // Why undefined AND null? :thinking:

  get activityFeed(): ActivityFeedItem[] {
    return this.feed.map((item) => ({
      ...item,
      unread: Boolean(this.unreadMap[item.activityId]),
    }));
  }

  get unreadFeed() {
    return this.feed.filter((item) => this.unreadMap[item.activityId]);
  }

  get unreadCount() {
    return this.unreadFeed.length;
  }

  /**
   * For react hook dependencies to detect changes in unread map without
   * needing to do additional calculations.
   */
  get unreadNonce() {
    return this._unreadNonce;
  }

  constructor(private app: AppController) {
    super();
    this.events = {
      on_update: [],
      on_unread_change: [],
    };
  }

  restart = (opts?: RestartOpts) => {
    const { pollInterval = DEFAULT_POLL_INTERVAL, updateNow = false } = opts || {};
    let restart = false;

    // if user ID is specified and different, set user ID and maybe restart
    if (!this.userId) {
      this.userId = this.app.user.me?.id;
      restart = true;
    }

    // if pollInterval is specified and different, set pollInterval
    // and maybe restart
    if (pollInterval && pollInterval !== this.pollInterval) {
      this.pollInterval = pollInterval;
      restart = true;
    }

    // only restart if we changed user ID and have a valid user ID.
    // If user ID is invalild, stop poll.
    // if nothing has changed, keep as is (don't restart and don't stop).
    if (this.userId) {
      if (restart) {
        this.startPoll(updateNow);
      }
    } else {
      this.stopPoll();
    }
  };

  private startPoll = (updateNow = false) => {
    if (!this.userId) {
      throw new Error('ActivityManager.startPoll error: userId not set');
    }
    this.stopPoll();
    this.pollId = setInterval(this.update, this.pollInterval);
    if (updateNow) {
      this.update();
    }
  };

  stopPoll = () => {
    if (this.pollId !== null) {
      clearInterval(this.pollId);
      this.pollId = null;
    }
  };

  setUserId = (userId: number | undefined) => {
    this.userId = userId;
  };

  getUserId = () => {
    return this.userId;
  };

  setPollInterval = (pollInterval: number) => {
    this.pollInterval = pollInterval;
  };

  getPollInterval = () => {
    return this.pollInterval;
  };

  private update = async () => {
    if (!this.userId) {
      throw new Error('ActivityManager.update error: userId not defined');
    }

    // determine if first time loading or not
    let firstLoad = false;
    if (this.feedState === ActivityFeedState.Idle) {
      firstLoad = true;
      this.feedState = ActivityFeedState.FirstLoading;
    } else {
      this.feedState = ActivityFeedState.Loading;
    }

    // fetch activity feed
    const activityResult = (await api.user.getAllActivity(`${this.userId}`)) as { result: ActivityItem[] };
    // filter out unknown activity types
    const nextRawActivityFeed: ActivityItem[] = activityResult.result.filter((item: ActivityItem) => activityTypes.includes(item.type as ActivityType));

    // if the same, do nothing
    if (isEqual(nextRawActivityFeed, this.rawFeed)) {
      // set to loaded
      this.feedState = ActivityFeedState.Loaded;
      return;
    }

    // make new unread map
    const nextUnreadMap = nextRawActivityFeed.reduce<Record<string, boolean>>((map, item) => {
      if (!item.readAt) {
        map[item.activityId] = true;
      }
      return map;
    }, {});

    const nextActivityFeed = new Set<ActivityItem>();

    // group related activities
    const lastGroupMap: Record<string, { lastItemTime: number; group: ActivityItem; added: boolean }> = {};
    for (const item of nextRawActivityFeed) {
      // not groupable type
      const groupByProfile = groupableByProfileType.includes(item.type as ActivityType);
      const groupByGame = groupableByGameType.includes(item.type as ActivityType);
      if (!groupByProfile && !groupByGame) {
        nextActivityFeed.add(item);
        continue;
      }

      const itemTime = new Date(item.createdAt).getTime();

      // grouped by unread/read, then subgroup by type, then subgroup by profile/game
      const readUnreadKey = nextUnreadMap[item.activityId] ? 'unread' : 'read';
      let subGroupKey: string;
      if (groupByGame) {
        if (item.offChainGameId) {
          subGroupKey = `game:offChainGameId:${item.offChainGameId}`;
        } else {
          subGroupKey = `game:gameId:${item.gameId}`;
        }
      } else if (groupByProfile) {
        subGroupKey = `profile:${item.userId}`;
      } else {
        // unknown grouping for some reason
        console.error('ActivityManager.update error:', 'Unknown subgrouping for', item.type);
        nextActivityFeed.add(item);
        continue;
      }
      const lastGroupKey = `group-${readUnreadKey}-${item.type}-${subGroupKey}`;

      let { group, lastItemTime, added } = lastGroupMap[lastGroupKey] ?? {};

      // if no group yet or the time of last item of last group is past the threshold, create new group
      if (!group || Math.abs(itemTime - lastItemTime) > GROUP_TIME_THRESHOLD) {
        group = {
          ...item,
          groupId: createGroupId(item.type, subGroupKey),
          items: [],
        };
        added = false;
        lastGroupMap[lastGroupKey] = { group, lastItemTime: 0, added };
      }

      // add activity to group
      group.items.push(item);
      lastGroupMap[lastGroupKey].lastItemTime = itemTime;

      // if we hit the minimum, remove existing items in the feed and add group to feed
      if (!added && group.items.length >= MIN_GROUP_ITEMS) {
        for (const groupItem of group.items) {
          nextActivityFeed.delete(groupItem);
        }
        nextActivityFeed.add(group);
        if (readUnreadKey === 'unread') {
          nextUnreadMap[group.activityId] = true;
        }
        added = true;
        lastGroupMap[lastGroupKey].added = true;
      }

      // if group hasn't been added to the feed, add activity to the feed
      if (!added) {
        nextActivityFeed.add(item);
      }
    }

    const unreadCount = this.unreadCount;
    this.rawFeed = nextRawActivityFeed;
    this.feed = Array.from(nextActivityFeed);
    this.unreadMap = nextUnreadMap;
    this.feedState = ActivityFeedState.Loaded;
    this.sendEvents([NotificationsEvent.FeedUpdate]);
    // we should always send this event in case the unreadCount is the same, but the unreadMap is different
    this._unreadNonce += 1;
    this.sendEvents([NotificationsEvent.UnreadChange]);
  };

  private getGroup = (groupId: string) => {
    return this.feed.find((item) => item.groupId === groupId);
  };

  private markItemAsRead = async (activityOrGroupId: number | string) => {
    // optimistic mark as read
    this.unreadMap[activityOrGroupId.toString()] = false;
    // actual mark as read
    let activityIds: number[];

    if (typeof activityOrGroupId === 'string') {
      // if string, it's a group id
      const group = this.getGroup(activityOrGroupId);
      activityIds = group?.items.map((item) => item.activityId) ?? [];
    } else {
      // if number, it's an activity id
      activityIds = [activityOrGroupId];
    }

    this._unreadNonce += 1;
    this.sendEvents([NotificationsEvent.UnreadChange]);

    if (activityIds.length) {
      await this.markActivitiesAsRead(activityIds);
    }
  };

  markSingleItemAsRead = async (activityOrGroupId: number | string) => {
    await this.markItemAsRead(activityOrGroupId);
  };

  markItemsAsRead = async (activityOrGroupIds: (number | string)[]) => {
    const groupIds: string[] = [];
    const activityIds: number[] = [];
    for (const id of activityOrGroupIds) {
      if (typeof id === 'string') {
        groupIds.push(id);
      } else {
        activityIds.push(id);
      }
    }

    for (const groupId of groupIds) {
      const group = this.getGroup(groupId);
      if (group?.items) {
        const childActivityIds = group.items.map((item) => item.activityId);
        activityIds.push(...childActivityIds);
      }
    }

    if (!activityIds.length) {
      return;
    }

    // dedupe activity IDs
    const activityIdSet = new Set(activityIds);
    const dedupedIds = Array.from(activityIdSet);

    // optimistic mark as read
    for (const id of dedupedIds) {
      this.unreadMap[id.toString()] = false;
    }

    this._unreadNonce += 1;
    this.sendEvents([NotificationsEvent.UnreadChange]);

    // actual mark as read
    await this.markActivitiesAsRead(dedupedIds);
  };

  markAllAsRead = async () => {
    if (!this.userId) {
      return;
    }
    const actualActivityIds = this.unreadFeed.map((item) => item.items?.map((child) => child.activityId) ?? item.activityId).flat();
    // Optimistc empties the unread map
    this.unreadMap = {};
    this._unreadNonce += 1;

    this.sendEvents([NotificationsEvent.UnreadChange]);

    // actual mark as read
    await this.markActivitiesAsRead(actualActivityIds);
  };

  clear = () => {
    this.stopPoll();
    this.userId = undefined;
    this.feed = [];
    this.feedState = ActivityFeedState.Idle;
    this.unreadMap = {};
    this.activityToGroupMap = {};
    this.error = undefined;
  };

  private markActivitiesAsRead = async (activityIds: number[]) => {
    this.error('markActivitiesAsRead', { activityIds });
    if (!activityIds.length) {
      return;
    }
    await api.user.markActivitiesAsRead(this.userId.toString(), activityIds);
  };
}
