export const getElementPointById = (id: string, centerX = false, centerY = false) => {
  const element = document.getElementById(id);

  if (element) {
    const bound = element.getBoundingClientRect();
    const point = { x: bound.left + (centerX ? bound.width / 2 : 0), y: bound.top + (centerY ? bound.height / 2 : 0) };
    return point;
  }

  return null;
};
