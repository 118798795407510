import { images } from 'assets/assets';
import { useNavigation } from 'hooks/useNavigation';
import { TopBarCurrency } from './TopBarCurrency';
import { Modals } from 'features/Modals';
import { useReRender } from 'hooks/useReRender';
import { controller } from 'lib/Controller';

export function TopBarCoinCurrency() {
  const { navigate } = useNavigation();

  const { visible, setComponent, state } = controller.ui.elements.TopBarCoinCurrency;

  const { coinBalance = 0 } = controller.user.me || {};

  useReRender({ id: 'TopBarCoinCurrency', listener: setComponent });

  const onClick = () => {
    // avoid interaction/navigation if any modal is open
    if (controller.modals.current?.id !== undefined) return;

    controller.modals.open(Modals.Error, {
      title: `Play. Make. Earn.`,
      message: '• Play games to win coins\n• Make and share games\n• Earn coins buying and selling items',
      btnText: 'ok',
    });
  };

  return (
    <TopBarCurrency type="coin" onClick={onClick} show={visible} icon={images.iconCoin} fakeBalance={state.displayBalance} userBalance={Number(coinBalance)} />
  );
}
