import { ApplyPlayRewardCoinRequest, CoinGemProps, GetCoinGemPriceProps } from '@storyverseco/svs-types';
import { pipelineApi, getEndpoint } from './base';
import { Endpoints } from './endpoints';
import { GameStateForReward, StepReward } from 'types/types';
import { GameLossPropeties } from '@storyverseco/svs-types';

const getUserBalance = async (): Promise<number> => {
  return (await pipelineApi.get(Endpoints.COIN_BALANCE_GET)) || 0;
};

interface CoinPrice {
  buyPrice: number;
  sellPrice: number;
}

const getBuyPrice = async (offChainGameId: number): Promise<CoinPrice> => {
  const data: GetCoinGemPriceProps = {
    offChainGameId,
    amount: 1,
  };
  return await pipelineApi.post(Endpoints.COIN_PRICE_BUY_GET, { data });
};

const getSellPrice = async (offChainGameId: number): Promise<CoinPrice> => {
  const data: GetCoinGemPriceProps = {
    offChainGameId,
    amount: 1,
  };
  return await pipelineApi.post(Endpoints.COIN_PRICE_SELL_GET, { data });
};

interface PriceDrift {
  message: string;
  newBuyPrice: number;
  newSellPrice: number;
}

interface BuyResponse {
  price?: string;
  gemsBought?: number;
  priceDrift?: PriceDrift;
}
const buy = async (offChainGameId: number, offeredPrice: number, hoid?: number): Promise<BuyResponse> => {
  const data: CoinGemProps = { offChainGameId, hoid, price: offeredPrice };
  return await pipelineApi.post(Endpoints.COIN_BUY, { data });
};

interface SellResponse {
  priceDrift?: PriceDrift;
}
const sell = async (offChainGameId: number, offeredPrice: number): Promise<SellResponse> => {
  const data: CoinGemProps = { offChainGameId, price: offeredPrice };
  return await pipelineApi.post(Endpoints.COIN_SELL, { data });
};

const buyCraftable = async (offChainGameId: number, gameData: any): Promise<void> => {
  const data = { offChainGameId, gameData };
  await pipelineApi.post(Endpoints.CRAFTABLE_BUY, { data });
};

const sellCraftable = async (offChainGameId: number, offeredPrice: number): Promise<SellResponse> => {
  const data: CoinGemProps = { offChainGameId, price: offeredPrice };
  return await pipelineApi.post(Endpoints.CRAFTABLE_SELL, { data });
};

const getRewards = async (gameId): Promise<StepReward[]> => {
  const response = await pipelineApi.post(Endpoints.COIN_REWARDS_GET, { data: { gameId } });
  return JSON.parse(atob(response));
};

const claimRewards = async (gameId, gameState: GameStateForReward): Promise<StepReward[]> => {
  const data = {
    gameId,
    token: btoa(JSON.stringify(gameState)),
  };

  return pipelineApi.post(Endpoints.COIN_REWARDS_CLAIM, { data });
};

const getGameGrowth = async (gameId: number): Promise<GameLossPropeties> => {
  return pipelineApi.get(getEndpoint(Endpoints.GAME_GROWTH, { gameId }));
};

export const coins = {
  getUserBalance,
  // getBuyPrice,
  // getSellPrice,
  buy,
  sell,
  buyCraftable,
  sellCraftable,
  getRewards,
  claimRewards,
  getGameGrowth,
};
