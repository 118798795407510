import { FollowRelationship, GemzUser } from '@storyverseco/svs-types';
import { CachedProfile, getProfileDefaults } from '../../pages/profile/profileTypes';
import { AppController } from '../Controller';
import { api } from '../apis';
import { FeatureCache } from '../cache/FeatureCache';
import { openShareOwnProfile, openShareProfile } from '../apis/share';
import { ComponentController } from './ComponentController';
import { FollowBtnType } from 'lib/followsTypes';
import { getHighResTwitterPic } from 'lib/twitterUtils';
import { FollowController, FollowEvent, disabledFollowBtnStates, followBtnStates } from './FollowController';
import { FollowState, getFollowBtnTypeFromStates } from '../followsUtils';
import { getHighResGemImage } from '../gemUtils';
import { isReactSnap } from 'config/consts';

const CACHE_EXPIRY_MS = 30000; // 30s

class ProfileFollowController extends ComponentController {
  constructor(private profile: ProfileController, private followCtl: FollowController) {
    super();

    this.addEventListener(FollowEvent.FollowStateChanged, ([username, _followState]: [string, FollowState]) => {
      if (this.profile.current.username === username) {
        this.updateComponent();
      }
    });
    this.addEventListener(FollowEvent.FollowRelationshipChanged, ([username, _followRelationship]: [string, FollowRelationship]) => {
      if (this.profile.current.username === username) {
        this.updateComponent();
      }
    });
  }

  get followRelationship(): FollowRelationship {
    if (this.isSelf) {
      return FollowRelationship.None;
    }
    return this.profile.current.username && this.followCtl.getFollowRelationship(this.profile.current.username);
  }

  get followState(): FollowState {
    if (this.isSelf) {
      return FollowState.Idle;
    }
    return this.profile.current.username && this.followCtl.getFollowState(this.profile.current.username);
  }

  get followBtnType(): FollowBtnType {
    if (this.isSelf) {
      return undefined;
    }
    return this.profile.current.username && getFollowBtnTypeFromStates(this.followRelationship, this.followState);
  }

  get showFollow() {
    if (this.isSelf) {
      return false;
    }
    return Boolean(this.followState && followBtnStates.includes(this.followState));
  }

  get isSelf() {
    return this.profile.current.isSelf;
  }

  get followDisabled() {
    return Boolean(this.isSelf || (this.followState && disabledFollowBtnStates.includes(this.followState)));
  }

  unfollow = async (): Promise<void> => {
    if (!this.profile.current.username) {
      throw new Error('ProfileFollowController.unfollow: No current profile');
    }
    return this.followCtl.unfollow(this.profile.current.username);
  };

  follow = (): Promise<void> => {
    if (!this.profile.current.username) {
      throw new Error('ProfileFollowController.follow: No current profile');
    }
    return this.followCtl.follow(this.profile.current.username);
  };

  loadFollowState = (): Promise<void> => {
    if (!this.profile.current.username) {
      throw new Error('ProfileFollowController.loadFollowState: No current profile');
    }
    return this.followCtl.follow(this.profile.current.username);
  };

  updateComponentFromProfile = () => {
    this.updateComponent();
  };
}

export class ProfileController extends ComponentController {
  private profileCache = new FeatureCache<Record<string, CachedProfile>>('profileCache', () => ({}));
  private gameViewsCounterCache = new FeatureCache<Record<string, number>>('gameViewsCounterCache', () => ({}));

  private _username = '';

  public social = new ProfileFollowController(this, this.app.follow);

  private onReady: (_: boolean) => void;
  public ready = new Promise((resolve) => (this.onReady = resolve));

  private currentTwitter?: GemzUser['twitter'];

  // @TODO: We should probably save the value of current in the cache
  get current() {
    return {
      username: this._username,
      ...this.getCacheData(this._username),
      isSelf: this.app.user.me?.username === this._username,
      shareUrl: '', // createShareProfileUrl({ username, queryParams: { sharePayload } });
      userNotFound: false,
    };

    /*
    export interface ProfileUser {
        walletAddress: WalletAddress;
        username: string;
        userTwitterHandle: string;
        userTwitterDisplayName: string;
        userProfilePic: string;
        userTwitterUrl: string;
        originalTwitterPic: string;
      }
    */
  }

  constructor(private app: AppController) {
    super();
  }

  private getCacheData = (username: string) => {
    return this.profileCache.get(username) || getProfileDefaults();
  };

  private setUsername = (username: string) => {
    this._username = username;
    // use this to prevent refetching the same data too quickly
    this.updateCache(username, { lastFetched: Date.now() });
    if (this._username !== '') {
      this.onReady?.(true);
    }
  };

  private updateCache = (username: string, data: Partial<CachedProfile>) => {
    this.profileCache.set(username, {
      ...this.getCacheData(username),
      ...data,
    });
  };

  private updateComponentAndSocial = () => {
    this.updateComponent();
    this.social.updateComponentFromProfile();
  };

  reloadSelfOnly = () => {
    if (this.app.user.me && this._username === this.app.user.me.username) {
      this.load(this.app.user.me.username, true);
    }
  };

  reload = () => {
    if (this._username) {
      this.load(this._username, true);
    }
  };

  load = (username: string, isReloading: Boolean = false): Promise<void> => {
    // skip fetching the same user data too quickly
    if (username === this._username && isReloading === false) {
      const cacheEllapsed = Date.now() - (this.profileCache.get(username)?.lastFetched || 0);
      if (cacheEllapsed < CACHE_EXPIRY_MS) {
        return;
      }
    }

    this.log('load', { username });

    this.setUsername(username);

    if (isReactSnap && username?.toLowerCase() === 'r') {
      return;
    }

    const promises = [
      // Load User Games
      api.user.getUserGamesByUsername(username).then((response) => {
        this.updateCache(username, { games: response });
        this.updateComponentAndSocial();
        this.updateViewsCount(response);
      }),
      // Load User Holdings
      api.user.getUserHoldingsByUsername(username, this.app.isAuth).then((response) => {
        // replace with high res gem image
        if (response?.items?.length) {
          for (const item of response.items) {
            item.gemIcon = getHighResGemImage(item.gemIcon);
          }
        }
        this.updateCache(username, { holdings: response });
        this.updateComponentAndSocial();
        this.updateViewsCount(response);
      }),
      // Load User Holders
      api.user.getUserHoldersByUsername(username, this.app.isAuth).then((response) => {
        this.updateCache(username, { holders: response });
        this.updateComponentAndSocial();
      }),
      // @TODO: Don't think we need to get this more than once (cache forever)
      // Load User Holders
      api.user.getUserByUsername(username).then((response) => {
        this.updateCache(username, {
          followerCount: Number(response.attributes.followerCount),
          followingCount: Number(response.attributes.followingCount),
          creatorLikesCount: Number(response.attributes.creatorLikesCount),
          userTwitterHandle: response.twitter?.handle,
          userTwitterDisplayName: response.twitter?.displayName,
          userProfilePic: getHighResTwitterPic(response.twitter?.picture),
          userTwitterUrl: `httsp://twitter.com/${response.twitter?.handle}`,
          originalTwitterPic: response.twitter?.picture,
        });
        this.updateComponentAndSocial();
      }),
      // Load liked games
      api.user.getUserLikedGamesByUsername(username).then((response) => {
        this.updateCache(username, { likedGames: response });
        this.updateComponentAndSocial();
      }),
    ];

    const promise = Promise.all(promises).then(() => {
      this.profileCache.save();
    });

    this.updateComponentAndSocial();

    return promise;
  };

  sendVisitNotification = () => {
    const { me } = this.app.user;

    // @TODO: Find out about currentProfile
    const { currentProfileUser } = {} as any;

    if (!me?.walletAddress) {
      return;
    }
    if (!currentProfileUser?.walletAddress) {
      return;
    }
    if (me.walletAddress === currentProfileUser.walletAddress) {
      return;
    }
    try {
      const notification = {
        type: 'visit',
        participant: me.walletAddress,
        user: currentProfileUser.walletAddress,
      };
      api.user.addActivity(notification);
    } catch (error) {
      // Fail silently
      console.error('Error adding activity:', error);
    }
  };

  share = () => {
    const { username, userTwitterDisplayName, shareUrl } = this.current;
    if (!username) {
      return;
    }

    if (this.current.isSelf) {
      openShareOwnProfile({
        username,
        url: shareUrl,
      });
    } else {
      openShareProfile({
        username,
        displayName: userTwitterDisplayName ?? username,
        url: shareUrl,
      });
    }
  };

  updateViewsCount(games: any) {
    games.items.forEach((game: any) => {
      this.updateSingleGameViewsCount(game.offChainGameId);
    });
  }

  async updateSingleGameViewsCount(id: string) {
    try {
      const counter = await api.feed.getGameViewsCount(id);
      this.gameViewsCounterCache.set(id, counter);
      this.gameViewsCounterCache.save();
      this.updateComponent();
    } catch (error) {
      console.error('Error updating game views count:', error);
    }
  }

  getGameViewsCount(id: string) {
    const counter = this.gameViewsCounterCache.get(id);
    if (!counter) {
      return '';
    }
    switch (true) {
      case counter > 1000:
        return `${(counter / 1000).toFixed(1)}K`;
      case counter > 1000000:
        return `${(counter / 1000000).toFixed(1)}M`;
      default:
        return counter;
    }
  }
}
