import { useMemo } from 'react';
import { simplifyNamesToString } from 'lib/textFormats';
import { ActivityFeedItem, ActivityItem } from 'features/Notifications/types';

export const useSimplifyNames = (activity: ActivityFeedItem | ActivityItem): string => {
  return useMemo(() => {
    const names = activity.items?.map((item) => item.participantDisplayName || item.participantName) ?? [
      activity.participantDisplayName || activity.participantName,
    ];
    const dedupedNames = Array.from(new Set(names));
    return simplifyNamesToString(dedupedNames, 2);
  }, [activity]);
};
