import { images } from '../assets/assets';
import { animations } from '../assets/assets';
import { GemType } from '@storyverseco/svs-types';

export const getIconFromGemType = (gemType: GemType) => {
  switch (gemType) {
    case 'diamond':
      return images.iconDiamond;
    case 'craftable':
      return images.iconCrafting;
    default:
      return images.iconCoin;
  }
};

export const getCurrencyAnimationAsset = (currencyType: string) => {
  switch (currencyType) {
    case 'diamond':
      return images.iconDiamond;
    case 'point':
      return images.iconPoints;
    default:
      return animations.coinSpin;
  }
};

export const getAssetType = (asset: string) => {
  if (asset.includes('.svg')) {
    return 'svg';
  } else if (asset.includes('.png')) {
    return 'png';
  } else if (asset.includes('.riv')) {
    return 'riv';
  } else {
    return '';
  }
};
