import { Outlet, Link } from 'react-router-dom';
import { Routes, getRouteWithParams } from '../router';
import { Game } from '../features/Game';
import { useNavigation } from '../hooks/useNavigation';
import { Suspense, lazy, useEffect, useState } from 'react';
import { controller } from '../lib/Controller';
import { ModalContainer } from '../features/Modals/ModalContainer';
import { useReRender } from 'hooks/useReRender';
import { sessionOpts, isReactSnap } from 'config/consts';
import { isDesktop, isMobile } from 'lib/device';
import { Toasts } from 'features/Notifications/Toasts/Toasts';
import { AppUpdateEvent, AppUpdateState, swUpdater } from 'lib/apis/swupdate';
import TopBar from 'features/TopBar/TopBar';
import { OneSignalPWAButton } from 'components/OneSignalPWAPermissionScreen';
import { Modals } from 'features/Modals';
import { FxContainer } from 'features/Fx/FxContainer';

const DesktopNavbar = lazy(() => import('../features/DesktopNavBar/DesktopNavBar'));
const BottomNavBar = lazy(() => import('../features/BottomNavBar/BottomNavBar'));

// When we are developing we want to support both bars showing up; on prod build we show one or the other
const useDesktopNavbar = sessionOpts.localDev || isDesktop();
const useMobileNavbar = sessionOpts.localDev || isMobile();

export const AppWrapper = () => {
  // Only place we should attach controller, so we can navigate from controller
  const { route, navigate } = useNavigation({ attachController: true });

  const isRoot = route === Routes.Root;

  useReRender({ id: 'AppWrapper', listener: controller.attachEventListener('on_auth_change') });
  useReRender({ id: 'AppWrapper/sessionEnabled', listener: controller.attachEventListener('session_enabled') });

  // If the app is loaded without a specific path `/`
  useEffect(() => {
    if (controller.isReady && isRoot) {
      console.log('AppWrapper', 'inital routing');
      navigate(getRouteWithParams(Routes.GameFeed));
    }
  }, [controller.isReady]);

  useEffect(() => {
    if (!controller.user.me && !controller.isSessionEnabled) {
      if (isReactSnap) {
        controller.setSessionEnabled(true);
      } else {
        controller.modals.open(Modals.InitialPassword, {
          disableBackdrop: true,
          onClose: () => {
            if (controller.isPWA) {
              controller.privy.login();
            }
          },
        });
      }
    }
  }, [controller.user.me]);

  if (!controller.isSessionEnabled) {
    return <ModalContainer />;
  }

  return (
    <div className="game-app">
      {useDesktopNavbar && (
        <Suspense fallback={<></>}>
          <DesktopNavbar />
        </Suspense>
      )}
      {useMobileNavbar && (
        <Suspense fallback={<></>}>
          <BottomNavBar />
        </Suspense>
      )}
      {/* Outlet renders the current page */}
      <Outlet />
      <TopBar />
      <Game />
      <ModalContainer />
      <FxContainer />
      <Toasts />
      <OneSignalPWAButton />
    </div>
  );
};
// This is the initial loading of our app. We basically wait on the controller to init.
// Business logic happens in the Controller.ts
export const appLoader = async () => {
  await controller.init();

  // auto update if available
  const maybeUpdateIfAvailable = () => {
    if (controller.visibilityState === 'background' && swUpdater.state === AppUpdateState.UpdateAvailable) {
      swUpdater.applyNewUpdate();
    }
  };

  // auto update on defocus if update is available
  controller.addEventListener('app_state_change', maybeUpdateIfAvailable);

  // auto update on update event if already unfocused
  swUpdater.addEventListener(AppUpdateEvent.StateChange, maybeUpdateIfAvailable);

  return true;
};

export const Component = AppWrapper;
