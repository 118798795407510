import { usePrivy, User as PrivyUser, useWallets, PrivyClientConfig } from '@privy-io/react-auth';
import { useEffect } from 'react';
import { controller } from '../../lib/Controller';
import { env } from '../../config/env';
import { waitFor } from 'lib/utils';
import { isMobile } from 'lib/device';
import { images } from 'assets/assets';

export const privyConfig = {
  defaultChain: env.l1Chain,
  supportedChains: [env.l1Chain, env.l2Chain],
  loginMethods: ['google', 'apple', 'sms'],
  appearance: {
    theme: 'dark',
    accentColor: '#ffb900',
    logo: 'https://media.storyverse.xyz/site/logo-gemz-gold-vertical.png',
  },
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
    noPromptOnSignature: true, // Prevents Privy UI to show up when we a sending transaction via embedded wallet
  },
} as PrivyClientConfig;

const MAX_RETRIES = 10;
const privyUIHack = async (tries = 0) => {
  const applyHack = (modalParent) => {
    const closeBtn = document.querySelector('#privy-modal-content [aria-label="close modal"]') as HTMLElement;
    if (closeBtn) {
      closeBtn.style.display = 'none';
    }

    const title = document.getElementById('privy-dialog-title');
    if (title) {
      title.style.fontSize = '20px';
      title.style.lineHeight = '1.2';
      title.style.marginTop = '120px';
      title.style.marginBottom = '10px';
    }

    const imgContainer = document.getElementById('privy-modal-content')?.children[0]?.children[0]?.children[1] as HTMLElement;
    if (imgContainer) {
      imgContainer.style.display = 'none';
    }

    const newImgContaier = document.getElementById('privy-dialog')?.children[1];
    if (newImgContaier) {
      const onboardingImg = new Image();
      onboardingImg.id = 'cai-test';
      onboardingImg.src = images.privyChest;
      onboardingImg.style.position = 'absolute';
      onboardingImg.style.display = 'none';
      newImgContaier.append(onboardingImg);
      onboardingImg.onload = () => {
        // Show modal when we done hacking
        modalParent.style.display = 'block';
        setTimeout(() => {
          onboardingImg.style.display = 'block';
          onboardingImg.style.top = isMobile() ? '25%' : '19%';
        }, 150);
      };
    }
  };

  const applyMinorHack = (modal) => {
    const closeBtn = document.querySelector('#privy-modal-content [aria-label="close modal"]') as HTMLElement;
    if (closeBtn) {
      closeBtn.style.display = 'none';
    }

    //Hide the "Protected by Privy" footer elements at all times.
    const footerElements = document.querySelectorAll('#privy-modal-content .hide-on-mobile');
    footerElements.forEach((element: Element) => {
      element.setAttribute('style', 'display: none;');
    });
  };

  const privyModal = document.getElementById('privy-dialog')?.children[1]?.children[0] as HTMLElement;
  if (privyModal) {
    // @TODO (carles): if you want to try uncomment these 2 lines and remove minorHack
    // privyModal.style.display = 'none';
    // applyHack(privyModal);
    applyMinorHack(privyModal);
  } else if (tries < MAX_RETRIES) {
    await waitFor(15);
    privyUIHack(++tries);
  } else {
    console.error('Failed to hack privy modal');
  }
};

export const PrivyConnect = () => {
  const { ready, authenticated, login, user, exportWallet, logout } = usePrivy();
  const { wallets } = useWallets();

  useEffect(() => {
    if (!ready) {
      return;
    }
    // Init auth controller once privy is ready
    controller.privy.init(authenticated);
    if (!authenticated) {
      // Make sure we reset the user if we are no longer authed, so we clear optimistic cache
      controller.user.reset();
    }
  }, [ready, authenticated]);

  useEffect(() => {
    if (user) {
      controller.privy.setUser(user);
    }
  }, [user]);

  useEffect(() => {
    const embeddedWallet = wallets.find((wallet) => wallet.walletClientType === 'privy');
    if (embeddedWallet) {
      controller.privy.setWallet(embeddedWallet);
    }
  }, [wallets]);

  useEffect(() => {
    if (login) {
      const myLogin = () => {
        login();
        setTimeout(() => {
          privyUIHack();
        });
      };
      controller.privy.setLogin(myLogin);
    }
  }, [login]);

  useEffect(() => {
    if (exportWallet) {
      controller.privy.setExportWallet(exportWallet);
    }
  }, [exportWallet]);

  useEffect(() => {
    if (logout) {
      controller.privy.setLogout(logout);
    }
  }, [logout]);

  return <></>;
};
