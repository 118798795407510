import { ExtraInfo, GameFeedItem } from '@storyverseco/svs-types';
import { api } from './apis';
import { EventListener, Fn } from './EventListener';
import { GameLikeState, allGameLikeStates, inProgressGameLikeStates } from './social';
import { AppController, controller } from './Controller';
import { formatEther } from 'viem';
import { ConditionWaiter } from './ConditionWaiter';
import { cancelSymbol, cancelizePromiseWithSymbol, waitForMs } from './wait';
import { formatBuyPrice, formatSellPrice } from 'lib/textFormats';
import { getHighResGemImage } from './gemUtils';

export enum FEGameEvent {
  Change = 'FEGameEvent/Change',
}

const createDefaultExtraInfo = (): ExtraInfo => ({
  canSell: false,
  commentCount: 0,
  creatorPortfolioWei: 0,
  gemBuyPrice: 0,
  gemsCount: 0,
  gemSellPrice: 0,
  holdersCount: 0,
  holdersImage: [],
  likeCount: 0,
  liked: false,
});

export class Game extends EventListener {
  get id() {
    return Number(this.data.id);
  }

  private _info?: ExtraInfo;
  get info() {
    return {
      ...this._info,
      gemBuyPrice: formatBuyPrice(this._info?.gemBuyPrice, this.data?.gemType),
      gemSellPrice: formatSellPrice(this._info?.gemSellPrice, this.data?.gemType),
      gemBuyRawPrice: this._info?.gemBuyPrice || 0,
      gemSellRawPrice: this._info?.gemSellPrice || 0,
    };
  }

  get storyUrl() {
    return this.data.gamePath;
  }

  get feedImage() {
    return this.data.multimediaUrls.mobilePortraitThumbnail;
  }

  get multimediaUrls() {
    return this.data.multimediaUrls;
  }

  get twitter() {
    return this.data.twitter;
  }

  get gem() {
    return {
      name: this.data.gemName,
      type: this.data.gemType,
      img: this.data.gemImage,
    };
  }

  get creatorAddress() {
    return this.data.creatorAddress;
  }

  get onChainGameId() {
    return this.data.onChainGameId;
  }

  private _likedStateWaiter = new ConditionWaiter(GameLikeState.Idle, (state) => !inProgressGameLikeStates.includes(state));

  private get _likedState(): GameLikeState {
    return this._likedStateWaiter.get();
  }

  private _optimisticLikeState = GameLikeState.Idle;
  private _optimisticCancel: () => void = undefined;

  // We could potentially take in a param some extra config, like if we auto fetch info on create
  constructor(private app: AppController, private data: Omit<GameFeedItem, 'winCondition'>) {
    super();
    // replace with high res gem image
    this.data.gemImage = getHighResGemImage(this.data.gemImage);
    this.fetchInfo();
  }

  private getGemPrice = (price: number | string | bigint = 0) => {
    // 2 equals to accept both 0 or '0' (soft equality check)
    if (price == 0) {
      return 0;
    }
    if (this.gem.type === 'diamond') {
      return formatEther(BigInt(price));
    } else {
      return Math.ceil(Number(price));
    }
  };

  private update = (updateFn: Fn<void>) => {
    updateFn();
    this.sendEvents([FEGameEvent.Change]);
  };

  private fetchInfo = async () => {
    if (!this.app.user.me) {
      return;
    }
    const info = await api.game.get.extraInfo(this.id);
    // we do some info exchange here for craftables
    if (this.gem.type === 'craftable') {
      this.app.craft.updateRawMissionData(this.id, info);
      // TODO: add holdings to user controller cache
      info.recipeProgress = this.app.craft.getGameExtraInfoRecipeProgress(this.id);
    }
    const gemPrice = this.getGemPrice(info.gemBuyPrice);
    this.update(() => {
      this._info = info;
      if (info.liked) {
        this._likedStateWaiter.set(GameLikeState.Liked);
      } else {
        this._likedStateWaiter.set(GameLikeState.Unliked);
      }
    });
  };

  /**
   * Optimistic liking/unliking before extraInfo loads in. Effectively part of
   * debouncing liking/unliking until extraInfo comes in, and uses the last
   * optimistic like state as basis for continuing the real like/unlike call.
   *
   * @returns `true` if the awaiting caller can continue. `false` if it should not.
   */
  private optimisticLikeOrUnlikeOnIdle = async (): Promise<boolean> => {
    if (this._likedState !== GameLikeState.Idle) {
      // continue on since it's not idle
      return true;
    }

    // cancelling last optimistic attempt
    this._optimisticCancel?.();
    this._optimisticCancel = undefined;

    if (this._optimisticLikeState === GameLikeState.Liked) {
      this._optimisticLikeState = GameLikeState.Unliked;
    } else {
      this._optimisticLikeState = GameLikeState.Liked;
    }

    // we're faking info until the real info comes in
    this.update(() => {
      this._info ??= createDefaultExtraInfo();
      if (this._optimisticLikeState === GameLikeState.Liked) {
        this._info.liked = true;
        this._info.likeCount = Math.max(0, this._info.likeCount + 1);
      } else {
        this._info.liked = false;
        this._info.likeCount = Math.max(0, this._info.likeCount - 1);
      }
    });

    // until real info comes in that sets the liked state
    const { promise, cancel } = cancelizePromiseWithSymbol(this._likedStateWaiter.wait());
    this._optimisticCancel = cancel;
    const likedStateOrCancelled = await promise;

    if (likedStateOrCancelled === cancelSymbol) {
      // cancelled, so don't continue this attempt
      return false;
    }

    const optimisticLikeState = this._optimisticLikeState;
    this._optimisticLikeState = GameLikeState.Idle; // reset

    // continue if optimistic like state does not match current like state
    return likedStateOrCancelled !== optimisticLikeState;
  };

  public likeOrUnlike = async () => {
    if (!this.app.isAuth) {
      controller.privy.login();
      return;
    }

    if (!this.app.user.me?.id) {
      return;
    }

    if (this._likedState === GameLikeState.Idle) {
      // use optimistic liking/unliking
      const shouldContinue = await this.optimisticLikeOrUnlikeOnIdle();
      if (!shouldContinue) {
        return;
      }
    }

    if (inProgressGameLikeStates.includes(this._likedState)) {
      return;
    }

    let prevGameLikeState: GameLikeState = undefined;
    let prevLikeCount = this.info.likeCount;
    let prevLiked = this.info.liked;
    try {
      if (this._likedState === GameLikeState.Liked) {
        prevGameLikeState = this._likedState;
        this.unlike();
      } else if (this._likedState === GameLikeState.Unliked) {
        prevGameLikeState = this._likedState;
        this.like();
      } else {
        throw new Error(`Unexpected this._likedState: ${this._likedState}`);
      }
    } catch (e) {
      console.error('likeOrUnlike error:', e);
      if (prevGameLikeState) {
        this._likedStateWaiter.set(prevGameLikeState);
      }

      this.update(() => {
        this._info.liked = prevLiked;
        this._info.likeCount = prevLikeCount;
      });
    }
  };

  public unlike = async () => {
    this._likedStateWaiter.set(GameLikeState.Unliking);

    // optimistically and visually update
    this.update(() => {
      this._info.liked = false;
      this._info.likeCount = Math.max(0, this.info.likeCount - 1);
    });

    await api.game.unlike(this.id);

    // purposely not awaiting
    api.feed.bookmarks
      .remove({ gameId: this.id, userId: this.app.user.me.id })
      .then((result) => {
        const failedResultEntries = Object.entries(result?.failedResults ?? {});
        if (failedResultEntries.length > 0) {
          console.error('likeOrUnlike bookmarks unlike failed result entries:', failedResultEntries);
          throw new Error('Error occurred during bookmarks unlike');
        }
      })
      .catch((e) => {
        console.error('likeOrUnlike bookmarks unlike error:', e);
      });

    this._likedStateWaiter.set(GameLikeState.Unliked);
  };

  public like = async () => {
    this._likedStateWaiter.set(GameLikeState.Liking);

    // optimistically and visually update
    this.update(() => {
      this._info.liked = true;
      this._info.likeCount = Math.max(0, this.info.likeCount + 1);
    });

    await api.game.like(this.id);

    // purposely not awaiting
    api.feed.bookmarks
      .add({ gameId: this.id, userId: this.app.user.me.id })
      .then((result) => {
        const failedResultEntries = Object.entries(result?.failedResults ?? {});
        if (failedResultEntries.length > 0) {
          console.error('likeOrUnlike bookmarks like failed result entries', failedResultEntries);
          throw new Error('Error occurred during bookmarks like');
        }
      })
      .catch((e) => {
        console.error('likeOrUnlike bookmarks like error:', e);
      });

    this._likedStateWaiter.set(GameLikeState.Liked);
  };

  refresh = () => {
    return this.fetchInfo();
  };

  /**
   * @deprecated Not really deprecated, but I want it to be striken so people read the note blow
   *
   * This should only be used to send game data to the viewer for preloading
   */
  getRawFeedData = (areYouTheViewer: boolean) => {
    // I know this is a silly API but I want to make sure People don't misuse this
    if (!areYouTheViewer) {
      return;
    }
    return this.data;
  };

  setCommentCount = (commentCount: number) => {
    if (this._info) {
      this.update(() => {
        this._info.commentCount = commentCount;
      });
    }
  };

  setLikeCount = (likeCount: number) => {
    if (this._info) {
      this.update(() => {
        this._info.likeCount = likeCount;
      });
    }
  };
}
