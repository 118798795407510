import type { AppController } from 'lib/Controller';
import { EventListener } from 'lib/EventListener';
import { CurrencyAnimationEffectOpts } from 'features/Fx/effects/CurrencyAnimationEffect';
import { GemType } from '@storyverseco/svs-types';

export class FXController extends EventListener {
  private _optsSequence: CurrencyAnimationEffectOpts[] = [];
  private addToOpts = (opts: CurrencyAnimationEffectOpts) => {
    this._optsSequence.push(opts);
  };
  private removeRecentFromOpts = () => {
    this._optsSequence.shift();
  };
  private clearOpts = () => {
    this._optsSequence = [];
  };

  get opts(): CurrencyAnimationEffectOpts {
    if (this._optsSequence.length === 0) return {};
    return { ...this._optsSequence[0] };
  }

  private _isShowingCurrencyAnimation: Boolean = false;
  get isShowingCurrencyAnimation(): Boolean {
    return this._isShowingCurrencyAnimation;
  }
  private set isShowingCurrencyAnimation(value: Boolean) {
    if (this._isShowingCurrencyAnimation === value) return;
    this._isShowingCurrencyAnimation = value;
    this.sendEvents(['on_change']);
  }

  private _isShowingConfettiAnimation: Boolean = false;
  get isShowingConfettiAnimation(): Boolean {
    return this._isShowingConfettiAnimation;
  }
  private set isShowingConfettiAnimation(value: Boolean) {
    if (this._isShowingConfettiAnimation === value) return;
    this._isShowingConfettiAnimation = value;
    this.sendEvents(['on_change']);
  }

  private onCurrencyAnimationComplete = () => {
    this.removeRecentFromOpts();
    this.isShowingCurrencyAnimation = false;
    if (this._optsSequence.length > 0) {
      setTimeout(() => {
        this.isShowingCurrencyAnimation = this._optsSequence.length > 0;
      }, 100);
    }
  };

  constructor(private app: AppController) {
    super();
  }

  public startCurrencyConfirmAnimation(currencyType: GemType, price: number) {
    this.startCurrencyAnimation({
      targetPoint: this.app.modals.modalBoxPoint,
      currencyType: currencyType,
      targetPrice: price,
      isShowingMultiple: true,
    });
  }

  public startCoinRewardAnimation(rewardAmount: number, isOutcome: boolean = false) {
    this.startCurrencyAnimation({
      targetPoint: this.app.input.clickPosition,
      currencyType: 'coin',
      targetPrice: rewardAmount,
      isShowingMultiple: isOutcome,
    });
  }

  public startPointsRewardAnimation(rewardAmount: number) {
    this.startCurrencyAnimation({
      targetPoint: this.app.modals.modalBoxPoint,
      currencyType: 'point',
      targetPrice: rewardAmount,
      isShowingMultiple: true,
    });
  }

  public startCurrencyAnimation(opts: CurrencyAnimationEffectOpts) {
    opts.onComplete = this.onCurrencyAnimationComplete;
    this.addToOpts(opts);
    if (this._optsSequence.length === 1) {
      this.isShowingCurrencyAnimation = true;
    }
  }

  public startConfettiAnimation() {
    this.isShowingConfettiAnimation = true;
  }

  public stopConfettiAnimation() {
    this.isShowingConfettiAnimation = false;
  }

  public stopAllAnimations() {
    this._isShowingCurrencyAnimation = false;
    this._isShowingConfettiAnimation = false;
    this.clearOpts();
    this.sendEvents(['on_change']);
  }
}
