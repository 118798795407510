import { controller } from '../../lib/Controller';
import { useReRender } from '../../hooks/useReRender';
import ConfettiEffect from 'features/Fx/effects/ConfettiEffect';
import CurrencyAnimationEffect from 'features/Fx/effects/CurrencyAnimationEffect';

export const FxContainer = () => {
  useReRender({ id: 'FX:Container', listener: controller.fx.attachEventListener('on_change') });

  return (
    <div className="fx-container">
      {controller.fx.isShowingConfettiAnimation && <ConfettiEffect show />}
      {controller.fx.isShowingCurrencyAnimation && <CurrencyAnimationEffect />}
    </div>
  );
};
