// --------------------------------------------------------
// Safe areas for PWA full screen

import { Optional } from '@storyverseco/svs-types';
import { controller } from '../Controller';
import { isAndroid, isMobile } from 'lib/device';

export const getKeyboardOffset = () => {
  // ANDROID
  // after the keyboard is open, viewport and window heihgt are different
  // this difference is what allows us to relocate the topbar correctly on all devices
  // but note that we must wait 0.5s for the keyboard to open first
  // interestingly, header and footer safe areas in android seem to be always 0
  // because of this, android behaves the same in browser and in pwa
  if (isAndroid()) {
    return window.innerHeight - visualViewport.height;
  }

  // iOS safari browser has a fixed offset
  // note: in iOS real device, there is an upper bar in safari browser, so we need to add 28px
  if (!controller.isPWA) {
    return 268 + 28;
  }

  // IOS
  // on ios, viewport and window height misteriously remain the same no matter what after keyboard is open
  // so we cannot use the difference to calculate the offset
  // mobile pwa depends on header safe area offset
  // the following seems to work fine for all ios devices
  const header = getHeaderSafeArea();
  const diff = 50 - header;
  const offsetY = 50 + diff + 330; // fixedOffset;
  return offsetY;
};

export const getHeaderSafeArea = () => {
  const top = document.getElementById('game-safe-area-top');
  if (top) {
    const topStyle = getComputedStyle(top);
    return parseInt(topStyle.getPropertyValue('height')) * 1;
  }
  console.error(`>>> getHeaderSafeArea:`, `could not find 'game-safe-area-top' element.`);
  return 0;
};

export const getFooterSafeArea = () => {
  const bottom = document.getElementById('game-safe-area-bottom');
  if (bottom) {
    const bottomStyle = getComputedStyle(bottom);
    return parseInt(bottomStyle.getPropertyValue('height')) * 1;
  }
  console.error(`>>> getFooterSafeArea:`, `could not find 'game-safe-area-bottom' element.`);
  return 0;
};

export const getGameSafeArea = () => {
  // calculate the vertical limits for the game to render bubbles and choices
  // this will be sent to the game whenever we need to render a story
  // note that magical extra safe areas are computed only for mobile pwa
  // and on desktop will always be 0
  const headerSafeArea = getHeaderSafeArea();
  const footerSafeArea = getFooterSafeArea();

  // this will push up choice buttons correctly for all layouts and devices
  // so choices appear on top of bottom-left story title
  // in case of pwa, substract the safe area.
  const headerUIHeight = 45;
  let footerUIHeight = 100;

  if (isMobile()) {
    footerUIHeight += 54;
  }

  // push choices up on ai assistant mode
  if (controller.ui.isAiAssistant) {
    if (controller.ui.isMobileLayout) {
      // on mobile, push choices up so they appear over input text
      const d = Math.max(getKeyboardOffset() * 0.65, 180);
      footerUIHeight += d;
    } else {
      // on desktop, push choices down since there is no bottom input text
      footerUIHeight += -40;
    }
  }

  // console.warn('>>> getGameSafeArea', headerSafeArea, footerSafeArea);

  const safeArea = {
    header: headerUIHeight + headerSafeArea,
    footer: footerUIHeight + footerSafeArea * 0.5,
  };

  // console.warn('>>> getSafeArea - footerSafeArea', footerSafeArea, 'footerSafeArea', footerSafeArea);

  return safeArea;
};

// --------------------------------------------------------

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const pwaDisplayModes = ['standalone', 'minimal-ui'];
const documentReadyStates = ['complete', 'interactive'];

let pwaInstallPrompt: Optional<BeforeInstallPromptEvent> = undefined;

const isPwaMedia = () => pwaDisplayModes.some((displayMode) => window.matchMedia(`(display-mode: ${displayMode})`).matches);
const isDocumentReady = () => documentReadyStates.some((readyState) => readyState === document.readyState);

const checkForPwa = () => {
  if ((navigator as any).standalone || isPwaMedia() || document.referrer.includes('android-app://')) {
    controller.setIsPWA(true);
  }
};

const onBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
  e.preventDefault(); // prevent normal install prompt
  pwaInstallPrompt = e; // store for later use
  controller.togglePWAPrompt(true);
};

const disablePwaInstallPrompt = () => {
  pwaInstallPrompt = undefined;
  controller.togglePWAPrompt(false);
};

export const startCheckForPwa = () => {
  // check if PWA
  if (isDocumentReady()) {
    checkForPwa();
  } else {
    window.addEventListener('DOMContentLoaded', checkForPwa, false);
  }

  // capture install prompt
  window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt, false);

  // responding to app installed
  window.addEventListener('appinstalled', disablePwaInstallPrompt, false);
};

export const triggerPwaInstall = async () => {
  if (!pwaInstallPrompt) {
    return;
  }

  // ask user to install
  await pwaInstallPrompt.prompt();

  // we can only call this specific prompt once, so disable afterwards
  disablePwaInstallPrompt();
};
