import { ActivityType, ChatMessageData, ChatMessageType, ChatReadReceiptData, ChatRoomData, ChatRoomStatsData } from '@storyverseco/svs-types';
import { ConditionWaiter } from '../ConditionWaiter';
import { AppController } from '../Controller';
import { debounceWaiterWrap } from '../DebounceWaiter';
import { api } from '../apis';
import { LoadState } from '../loadState';
import { hoursToMs, minutesToMs, secondsToMs } from '../time';
import { removeNewLines, collapseWhiteSpace, isSameNums } from '../utils';
import { ComponentController } from './ComponentController';
import isEqual from 'lodash.isequal';
import { MessagesUpdater, PresenceUpdater } from '../chatTypes';

export enum SingleRoomEvent {
  RoomChange = 'SingleRoomEvent/RoomChange',
  RoomLoadStateChange = 'SingleRoomEvent/RoomLoadStateChange',
  StatsChange = 'SingleRoomEvent/StatsChange',
  StatsLoadStateChange = 'SingleRoomEvent/StatsLoadStateChange',
  MessageLoadStateChange = 'SingleRoomEvent/MessageLoadStateChange',
  MessageListChange = 'SingleRoomEvent/MessageListChange',
  NewMessages = 'SingleRoomEvent/NewMessages',
  ReachedBeginning = 'SingleRoomEvent/ReachedBeginning',
  VisibilityChange = 'SingleRoomEvent/VisibilityChange',
  ReadReceiptChange = 'SingleRoomEvent/ReadReceiptChange',
  ReadReceiptLoadStateChange = 'SingleRoomEvent/ReadReceiptLoadStateChange',
  UnreadCountChange = 'SingleRoomEvent/UnreadCountChange',
}

let ephMsgIdPool = 0;

export const createEphMsgId = () => {
  return `ephmsg-${ephMsgIdPool++}`;
};

export interface EphMessage extends ChatMessageData {
  ephemeral?: boolean;
  ephId?: string;
  loadState?: LoadState;
}

export const isMessageEphemeral = (msg: ChatMessageData): msg is EphMessage => {
  return Boolean((msg as any).ephemeral);
};

export class SingleRoomController extends ComponentController {
  pageSize = 15;

  private roomWaiter = new ConditionWaiter<ChatRoomData>();

  private _messages: ChatMessageData[] = [];

  private _stats: ChatRoomStatsData = undefined;

  private _readReceipt: ChatReadReceiptData = undefined;

  private _messageLoadState = LoadState.Idle;

  private _roomLoadState = LoadState.Idle;

  private _statsLoadState = LoadState.Idle;

  private _readReceiptLoadState = LoadState.Idle;

  private _reachedBeginning = false;

  private _visible = false;

  private _unreadMessagesCount = 0;

  // TODO svs-config this limit
  private _charLimit = 140;

  private idMessageMap: Record<number, ChatMessageData> = {};

  private checkingFromRoomList = false;

  private messagesUpdater: MessagesUpdater;
  private presenceUpdater: PresenceUpdater;

  get messageLoadState(): LoadState {
    return this._messageLoadState;
  }

  get roomLoadState(): LoadState {
    return this._roomLoadState;
  }

  get statsLoadState(): LoadState {
    return this._statsLoadState;
  }

  get readReceiptLoadState(): LoadState {
    return this._readReceiptLoadState;
  }

  get room(): ChatRoomData | undefined {
    return this.roomWaiter.get();
  }

  get stats(): ChatRoomStatsData | undefined {
    return this._stats;
  }

  get readReceipt(): ChatReadReceiptData | undefined {
    return this._readReceipt;
  }

  get unreadMessagesCount(): number {
    return this._unreadMessagesCount;
  }

  get messages(): ChatMessageData[] {
    if (this.room && this.messageLoadState === LoadState.Idle) {
      this.loadFirstMessages()
        .then(() => this.loadReadReceipt())
        .catch((e) => {
          console.error('SingleRoomController.messages error:', e);
        });
    }
    return this._messages;
  }

  get latestMessage(): ChatMessageData | undefined {
    if (this.room && this.messageLoadState === LoadState.Idle) {
      this.loadFirstMessages()
        .then(() => this.loadReadReceipt())
        .catch((e) => {
          console.error('SingleRoomController.latestMessage error:', e);
        });
    }
    return this._messages[this._messages.length - 1] ?? undefined;
  }

  get reachedBeginning(): boolean {
    return this._reachedBeginning;
  }

  get charLimit(): number {
    return this._charLimit;
  }

  get visible(): boolean {
    return this._visible;
  }

  constructor(public app: AppController, room: ChatRoomData = undefined) {
    super();
    this.messagesUpdater = new PollingSingleRoomMessagesUpdater(this);
    this.presenceUpdater = new PollingSingleRoomPresenceUpdater(this, (onlineCount) => {
      if (onlineCount !== this._stats.onlineCount) {
        this._stats.onlineCount = onlineCount;
        this.updateComponent();
        this.sendEvents([SingleRoomEvent.StatsChange]);
      }
    });

    if (room) {
      this.roomWaiter.set(room);
      this._roomLoadState = LoadState.Loaded;
    }
  }

  setRoom = (room: ChatRoomData, forceUpdate = false) => {
    if (!forceUpdate && this.roomWaiter.get() === room) {
      return;
    }

    this.roomWaiter.set(room);
    this.updateComponent();
    this.sendEvents([SingleRoomEvent.RoomChange]);
  };

  waitForRoom = (): Promise<ChatRoomData> => this.roomWaiter.wait();

  setCheckingFromRoomList = (enabled: boolean): void => {
    this.checkingFromRoomList = enabled;
    this.maybeStartOrStopChecking();
  };

  loadStats = debounceWaiterWrap(async (force = false): Promise<ChatRoomStatsData> => {
    if (!force && this.statsLoadState === LoadState.Loaded) {
      return this._stats;
    }

    if (!this.room) {
      throw new Error('SingleRoomController.loadStats: no room assigned');
    }

    this.setStatsLoadState(LoadState.Loading);

    try {
      const stats = await api.chat.get.stats(this.room.id);
      // copying this over since we're setting online count separately
      if (stats.onlineCount === undefined && this._stats?.onlineCount !== undefined) {
        stats.onlineCount = this._stats.onlineCount;
      }
      if (isEqual(stats, this._stats)) {
        return this._stats;
      }
      this._stats = stats;
      this.setStatsLoadState(LoadState.Loaded);
      this.sendEvents([SingleRoomEvent.StatsChange]);
      return stats;
    } catch (e) {
      console.error('SingleRoomController.loadStats error:', e);
      this.setStatsLoadState(LoadState.Errored);
      throw e;
    }
  });

  loadReadReceipt = debounceWaiterWrap(async (force = false): Promise<ChatReadReceiptData> => {
    if (!force && this._readReceiptLoadState === LoadState.Loaded) {
      return this._readReceipt;
    }

    if (!this.room) {
      throw new Error('SingleRoomController.loadReadReceipt: no room assigned');
    }

    this.setReadReceiptLoadState(LoadState.Loading);

    try {
      const receipt = await api.chat.get.roomReceipt(this.room.id);
      if (!receipt && !this._readReceipt) {
        this.setReadReceiptLoadState(LoadState.Loaded);
        return this._readReceipt;
      }
      if (isEqual(receipt, this._readReceipt)) {
        return this._readReceipt;
      }
      this.setReadReceipt(receipt);
      this.setReadReceiptLoadState(LoadState.Loaded);
      return receipt;
    } catch (e) {
      console.error('SingleRoomController.loadReceipt error:', e);
      this.setReadReceiptLoadState(LoadState.Errored);
      throw e;
    }
  });

  loadFirstMessages = debounceWaiterWrap(async (): Promise<ChatMessageData[]> => {
    if (this.messageLoadState === LoadState.Loaded) {
      return this._messages;
    }

    if (!this.room) {
      throw new Error('SingleRoomController.loadFirstMessages: no room assigned');
    }

    this.setMessageLoadState(LoadState.Loading);

    const before = new Date().toISOString();

    try {
      const messages = await api.chat.get.roomMessages(this.room.id, { limit: this.pageSize, offset: 0, before });
      this._messages = this.getNewMessagesOnly(messages);
      this.processMessages(this._messages);
      this.setMessageLoadState(LoadState.Loaded);
      this.sendEvents([SingleRoomEvent.MessageListChange]);
      if (messages.length < this.pageSize) {
        this._reachedBeginning = true;
        this.sendEvents([SingleRoomEvent.ReachedBeginning]);
      }
      this.calculateUnreadMessageCount();
    } catch (e) {
      console.error('SingleRoomController.loadFirstMessages error:', e);
      this.setMessageLoadState(LoadState.Errored);
      throw e;
    }
  });

  loadPreviousMessages = debounceWaiterWrap(async (): Promise<ChatMessageData[]> => {
    if (this._reachedBeginning) {
      return [];
    }
    if (this.messageLoadState === LoadState.Idle) {
      return await this.loadFirstMessages();
    }

    this.setMessageLoadState(LoadState.Loading);

    let before: string = undefined;
    if (this._messages.length > 0) {
      before = new Date(this._messages[0].createdAt).toISOString();
    } else {
      before = new Date().toISOString();
    }

    try {
      let prevMessages = await api.chat.get.roomMessages(this.room.id, { limit: this.pageSize, before });
      this.setMessageLoadState(LoadState.Loaded);
      prevMessages = this.getNewMessagesOnly(prevMessages);
      if (prevMessages.length > 0) {
        this.processMessages(prevMessages);
        this._messages = prevMessages.concat(this._messages);
        this.sendEvents([SingleRoomEvent.MessageListChange]);
        this.calculateUnreadMessageCount();
      } else {
        this._reachedBeginning = true;
        this.sendEvents([SingleRoomEvent.ReachedBeginning]);
      }
    } catch (e) {
      console.error('SingleRoomController.loadPreviousMessages error:', e);
      this.setMessageLoadState(LoadState.Errored);
      throw e;
    }
  });

  addMessage = async (text: string): Promise<ChatMessageData> => {
    if (!this.room) {
      throw new Error(`SingleRoomController.addMessage: no room assigned`);
    }
    if (!this.app.isAuth) {
      throw new Error(`SingleRoomController.addMessage: no auth`);
    }

    text = this.sanitizeMessage(text);

    if (!text.length) {
      throw new Error('SingleRoomController.addMessage: empty message');
    }

    // ephemeral message until the real one comes back
    const user = this.app.user.me;
    const date = new Date();
    const ephId = createEphMsgId();
    const ephMessage: EphMessage = {
      ephemeral: true,
      ephId,
      id: 0,
      type: ChatMessageType.Text,
      createdAt: date.toISOString(),
      updatedAt: date.toISOString(),
      roomId: this.room.id,
      senderId: user.id,
      sender: user,
      text,
      loadState: LoadState.Loading,
    };
    this._messages.push(ephMessage);

    // clone the messages to trigger any ref-based dependencies
    this._messages = this._messages.slice();

    this.updateComponent();
    this.sendEvents([SingleRoomEvent.MessageListChange]);

    // send message
    try {
      const newMessage = await api.chat.post.message(this.room.id, {
        type: ChatMessageType.Text,
        text,
      });
      this.processMessages([newMessage]);

      // _messages could've changed mid-request, so we need to find it and replace it
      let found = false;
      for (let i = 0; i < this._messages.length && !found; i += 1) {
        const msg = this._messages[i];
        if (isMessageEphemeral(msg) && msg.ephId === ephId) {
          this._messages[i] = newMessage;
          found = true;
        }
      }

      // if not found for whatever reason, just add to the end
      if (!found) {
        this._messages.push(newMessage);
      }

      // clone the messages to trigger any ref-based dependencies
      this._messages = this._messages.slice();

      this.updateComponent();
      this.sendEvents([SingleRoomEvent.MessageListChange]);
      this.calculateUnreadMessageCount();

      return newMessage;
    } catch (e) {
      ephMessage.loadState = LoadState.Errored;
      this.updateComponent();
      this.sendEvents([SingleRoomEvent.MessageListChange]);
      throw e;
    }
  };

  setRoomLoadState = (state: LoadState, forceUpdate = false): void => {
    if (!forceUpdate && state === this._roomLoadState) {
      return;
    }

    this._roomLoadState = state;
    this.sendEvents([SingleRoomEvent.RoomLoadStateChange]);
    this.updateComponent();
  };

  appendMessages = (messages: ChatMessageData[]): void => {
    if (messages.length === 0) {
      return;
    }

    messages = this.getNewMessagesOnly(messages);
    if (messages.length === 0) {
      return;
    }

    this._messages = this._messages.concat(messages);
    this.processMessages(messages);
    this.sendEvents([SingleRoomEvent.MessageListChange, SingleRoomEvent.NewMessages]);
    this.calculateUnreadMessageCount();
  };

  setMessageLoadState = (state: LoadState, forceUpdate = false): void => {
    if (!forceUpdate && state === this._messageLoadState) {
      return;
    }
    this._messageLoadState = state;
    this.sendEvents([SingleRoomEvent.MessageLoadStateChange]);
    this.updateComponent();
  };

  setReadReceipt = (readReceipt: ChatReadReceiptData, forceUpdate = false): void => {
    if (!forceUpdate && isEqual(this._readReceipt, readReceipt)) {
      return;
    }

    this._readReceipt = readReceipt;
    this.sendEvents([SingleRoomEvent.ReadReceiptChange]);
    this.calculateUnreadMessageCount();
  };

  setReadReceiptLoadState = (state: LoadState, forceUpdate = false): void => {
    if (!forceUpdate && state === this._readReceiptLoadState) {
      return;
    }

    this._readReceiptLoadState = state;
    this.sendEvents([SingleRoomEvent.ReadReceiptLoadStateChange]);
    this.updateComponent();
  };

  updateReadReceipt = async (messageId: number): Promise<void> => {
    if (!this.room) {
      throw new Error('SingleRoomController.updateReadReceipt error: no room assigned');
    }

    if (this._readReceipt?.messageId === messageId) {
      return;
    }

    try {
      const readReceipt = await api.chat.post.roomReceipt(this.room.id, { messageId });
      this.setReadReceipt(readReceipt);
    } catch (e) {
      console.error('SingleRoomController.updateReadReceipt error:', e);
      throw e;
    }
  };

  protected setStatsLoadState = (state: LoadState, forceUpdate = false): void => {
    if (!forceUpdate && state === this._statsLoadState) {
      return;
    }
    this._statsLoadState = state;
    this.sendEvents([SingleRoomEvent.StatsLoadStateChange]);
    this.updateComponent();
  };

  protected onVisibilityChange = () => {
    this.maybeStartOrStopChecking();
    // only trigger this if actually different
    if (this._visible !== this.isVisible) {
      this._visible = this.isVisible;
      this.sendEvents([SingleRoomEvent.VisibilityChange]);
    }
  };

  private maybeStartOrStopChecking = (): void => {
    if (this.isVisible || this.checkingFromRoomList) {
      if (this.messageLoadState === LoadState.Idle) {
        this.waitForRoom()
          .then(() => this.loadFirstMessages())
          .catch((e) => {
            console.error('SingleRoomController.onVisibilityChange loadFirstMessages error:', e);
          });
      }
      this.messagesUpdater.startCheckingForMessages(true);
      this.presenceUpdater.startCheckingForOnline(true);
    } else {
      this.messagesUpdater.stopCheckingForMessages();
      this.presenceUpdater.stopCheckingForOnline();
    }
  };

  private sanitizeMessage = (msg: string): string => {
    msg = removeNewLines(msg);
    msg = collapseWhiteSpace(msg);
    msg = msg.trim();
    if (msg.length > this._charLimit) {
      msg = msg.substring(0, this._charLimit);
    }
    return msg;
  };

  private processMessages = (messages: ChatMessageData[]): void => {
    for (const message of messages) {
      this.idMessageMap[message.id] = message;
    }
    this.presenceUpdater.forceCheck();
  };

  private getNewMessagesOnly = (messages: ChatMessageData[]): ChatMessageData[] => {
    return messages.filter((m) => !this.idMessageMap[m.id]);
  };

  private calculateUnreadMessageCount = () => {
    if (!this._messages?.length) {
      return;
    }

    if (!this._readReceipt) {
      // if no read receipt but has at least one message, count at least 1 unread
      if (this._unreadMessagesCount !== 1) {
        this._unreadMessagesCount = 1;
        this.sendEvents([SingleRoomEvent.UnreadCountChange]);
      }
      return;
    }

    // start from end
    let unreadCount = 0;
    let readIndex = -1;
    for (let i = this._messages.length - 1; i >= 0; i -= 1) {
      const message = this._messages[i];
      if (message.id === this._readReceipt.messageId) {
        unreadCount = this._messages.length - i - 1;
        readIndex = i;
        break;
      }
    }

    if (unreadCount !== this._unreadMessagesCount) {
      this._unreadMessagesCount = unreadCount;
      this.sendEvents([SingleRoomEvent.UnreadCountChange]);
    }

    // mark chat activities in this room as read
    if (readIndex !== -1) {
      const lastReadMessageTime = new Date(this._messages[readIndex].createdAt).getTime();
      // add relevant message ids to set for faster/easier checking
      const readChatMsgSet = new Set(this._messages.slice(0, readIndex + 1).map((msg) => msg.id));
      const { unreadFeed } = this.app.notifications;
      // filter by chat new message type and room id
      const unreadChatActivities = unreadFeed.filter((item) => item.type === ActivityType.ChatNewMessage && item.extra?.roomId === this.room.id);
      const markIdsAsRead: number[] = [];
      for (const activity of unreadChatActivities) {
        if (activity.extra?.messageId && readChatMsgSet.has(activity.extra.messageId)) {
          // check and mark if same message id
          markIdsAsRead.push(activity.activityId);
        } else {
          // check and mark if older than last read message
          const activityTime = new Date(activity.createdAt).getTime();
          if (activityTime < lastReadMessageTime) {
            markIdsAsRead.push(activity.activityId);
          }
        }
      }
      // purposely not awaiting
      this.app.notifications.markItemsAsRead(markIdsAsRead).catch((e) => {
        console.error('SingleRoomController.calculateUnreadMessageCount markItemsAsRead error:', e);
      });
    }
  };
}

class PollingSingleRoomMessagesUpdater implements MessagesUpdater {
  private intervalId: ReturnType<typeof setInterval> = undefined;

  private intervalTime = secondsToMs(5); // every 5 seconds

  private checkingEnabled = false;

  private initDateIso = new Date().toISOString();

  constructor(private controller: SingleRoomController) {}

  startCheckingForMessages(checkNow = false): void {
    if (!this.controller.app.isAuth) {
      // need to be logged in
      throw new Error('PollingSingleRoomUpdater.startCheckingForMessages: not logged in');
    }
    if (this.intervalId !== undefined) {
      // already started
      return;
    }
    if (this.checkingEnabled) {
      // already started
      return;
    }

    this.checkingEnabled = true;
    // start when room is ready
    this.controller.waitForRoom().then(() => {
      // check for flag again in case it changed mid-waiting
      if (this.checkingEnabled) {
        this.intervalId = setInterval(this.poll, this.intervalTime);
        if (checkNow) {
          this.poll();
        }
      }
    });
  }
  stopCheckingForMessages(): void {
    this.checkingEnabled = false;
    if (this.intervalId === undefined) {
      return;
    }

    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  private poll = async (): Promise<void> => {
    if (!this.controller.room) {
      throw new Error('PollingSingleRoomUpdater.startCheckingForMessages: no room assigned');
    }
    if (!this.controller.app.isAuth) {
      throw new Error('PollingSingleRoomUpdater.startCheckingForMessages: not logged in');
    }

    let nextMessages: ChatMessageData[];
    const limit = this.controller.pageSize;
    let newMessages: ChatMessageData[] = [];
    this.controller.setMessageLoadState(LoadState.Loading);

    try {
      let latestMessage: ChatMessageData = undefined;

      // first use non-self messages for basis
      const me = this.controller.app.user.me;
      for (let i = this.controller.messages.length - 1; i >= 0 && !latestMessage; i -= 1) {
        if (!isSameNums(me.id, this.controller.messages[i].sender.id)) {
          latestMessage = this.controller.messages[i];
        }
      }

      // if no non-self message exists, use any latest message
      if (!latestMessage && this.controller.messages.length > 0) {
        latestMessage = this.controller.messages[this.controller.messages.length - 1];
      }

      const after = latestMessage?.createdAt ?? this.initDateIso ?? new Date().toISOString();
      do {
        nextMessages = [];
        const offset = newMessages.length;

        nextMessages = await api.chat.get.roomMessages(this.controller.room.id, { offset, limit, after });

        newMessages = newMessages.concat(nextMessages);
      } while (nextMessages.length >= limit);

      this.controller.setMessageLoadState(LoadState.Loaded);
      if (newMessages.length) {
        this.controller.appendMessages(newMessages);
      }
    } catch (e) {
      console.error('PollingSingleRoomUpdater.poll error:', e);
      this.controller.setMessageLoadState(LoadState.Errored);
    }
  };
}

// TODO a socket/pushed based single room updater?

class PollingSingleRoomPresenceUpdater implements PresenceUpdater {
  private intervalId: ReturnType<typeof setInterval> = undefined;

  private intervalTime = minutesToMs(5); // every 5 minutes

  private checkingEnabled = false;

  constructor(private controller: SingleRoomController, private onOnlineCountUpdate: (onlineCount: number) => void) {}

  startCheckingForOnline = (checkNow?: boolean): void => {
    if (!this.controller.app.isAuth) {
      // need to be logged in
      throw new Error('PollingSingleRoomUpdater.startCheckingForMessages: not logged in');
    }
    if (this.intervalId !== undefined) {
      // already started
      return;
    }
    if (this.checkingEnabled) {
      // already started
      return;
    }

    this.checkingEnabled = true;
    // start when room is ready
    this.controller.waitForRoom().then(() => {
      // check for flag again in case it changed mid-waiting
      if (this.checkingEnabled) {
        this.intervalId = setInterval(this.poll, this.intervalTime);
        if (checkNow) {
          this.poll();
        }
      }
    });
  };

  stopCheckingForOnline = (): void => {
    this.checkingEnabled = false;
    if (this.intervalId === undefined) {
      return;
    }

    clearInterval(this.intervalId);
    this.intervalId = undefined;
  };

  forceCheck = (): void => {
    this.poll();
  };

  private poll = (): void => {
    if (!this.controller.stats) {
      return;
    }
    this.onOnlineCountUpdate(this.getFauxOnlineCount());
  };

  private getFauxOnlineCount = (): number => {
    const tenMinutesAgoTime = Date.now() - minutesToMs(10);
    const activeUserIdSet = new Set<number>();
    for (const message of this.controller.messages) {
      const messageTime = new Date(message.createdAt).getTime();
      if (messageTime >= tenMinutesAgoTime) {
        activeUserIdSet.add(message.senderId);
      }
    }
    return activeUserIdSet.size;
  };
}
