import { images } from 'assets/assets';
import { useNavigation } from 'hooks/useNavigation';
import { TopBarCurrency } from './TopBarCurrency';
import { Routes } from 'router';
import { useReRender } from 'hooks/useReRender';
import { controller } from 'lib/Controller';

export function TopBarDiamondCurrency() {
  const { navigate } = useNavigation();

  const { visible, setComponent, state } = controller.ui.elements.TopBarDiamondCurrency;

  const { diamondBalance = 0, isDepositPending } = controller.user.me || {};

  useReRender({ id: 'TopBarDiamondCurrency', listener: setComponent });

  const onClick = () => {
    // avoid interaction/navigation if any modal is open
    if (controller.modals.current?.id) return;
    navigate(Routes.Deposit);
  };

  return (
    <TopBarCurrency
      type="diamond"
      onClick={onClick}
      show={visible}
      icon={images.iconDiamond}
      fakeBalance={state.displayBalance}
      userBalance={Number(diamondBalance)}
      isPending={isDepositPending}
    />
  );
}
