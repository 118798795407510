import OneSignal from 'react-onesignal';
import { useState } from 'react';
import { useClassName } from 'hooks/useClassName';
import { controller } from '../../lib/Controller';
import { useReRender } from 'hooks/useReRender';
import { UserControllerEvent } from 'lib/controllers/auth/UserController';
import './OneSignalPWAButton.scss';

export const OneSignalPWAButton = () => {
  const [wasClicked, setWasClicked] = useState(false);
  const popupClassName = useClassName('account-section', wasClicked && 'hide', !controller.user.me && 'hide');
  useReRender({ id: 'OnesignalPWAPermissionButton', listener: controller.user.attachEventListener(UserControllerEvent.onOneSignalPermissionChange) });

  const onClick = () => {
    OneSignal.Notifications.requestPermission();
    setWasClicked(true);
  };

  if (OneSignal.Notifications.permission) {
    return null;
  }

  if (!controller.isPWA) {
    return null;
  }

  return (
    <div id="pwa-permissions-screen" onClick={onClick} className={popupClassName}>
      {' '}
    </div>
  );
};
