export const getRandomizedPointWithinRadius = (point: { x: number; y: number }, radius: number) => {
  const randomX = point.x + (Math.random() - 0.5) * 2 * radius;
  const randomY = point.y + (Math.random() - 0.5) * 2 * radius;
  return { x: randomX, y: randomY };
};

/**
 * Finds the control points for a quadratic Bezier curve given a start point, an end point,
 * and a distance ratio that determines the perpendicular distance from the midpoint.
 *
 * Given start and end points, this function calculates a control point such that:
 * 1. The control point is perpendicular to the line segment connecting the start and end points.
 * 2. The control point is at a distance ratio from the midpoint of the segment.
 *
 * Example:
 *
 * Start Point: (1, 1)
 * End Point: (5, 1)
 * Midpoint: (3, 1)
 *
 * If distanceRatio is 1.0 (100%), the perpendicular point forms an isosceles right triangle:
 *
 *          Control Point
 *            |
 *            |
 *            |
 * (1,1)---(3,1)---(5,1)
 *  Start   Midpoint  End
 *
 * @param startPoint - The start point with x and y coordinates.
 * @param endPoint - The end point with x and y coordinates.
 * @param distanceRatio - The ratio of the perpendicular distance from the midpoint.
 * @returns An array containing the start point, the control point, and the end point.
 */
export const findQuadraticBezierCurve = (startPoint: { x: number; y: number }, endPoint: { x: number; y: number }, distanceRatio: number) => {
  const { x: x1, y: y1 } = startPoint;
  const { x: x2, y: y2 } = endPoint;

  // Calculate the midpoint
  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;

  // Calculate the direction vector from point 1 to point 2
  const dx = x2 - x1;
  const dy = y2 - y1;

  // Calculate the length of the direction vector
  const length = Math.sqrt(dx * dx + dy * dy);

  // Normalize the direction vector to get the unit vector
  const unitDx = dx / length;
  const unitDy = dy / length;

  // Calculate the perpendicular vector (swap and negate one component)
  let perpDx = -unitDy;
  let perpDy = unitDx;

  // Ensure the perpendicular direction is upward
  if (perpDy > 0) {
    perpDx = -perpDx;
    perpDy = -perpDy;
  }

  // Calculate the base distance which is half the length between the two points
  const baseDistance = length / 2;

  // Calculate the actual perpendicular distance using the distanceRatio
  const perpendicularDistance = baseDistance * distanceRatio;

  // Calculate the control point at the specified distanceRatio from the midpoint
  const controlPointX = midX + perpDx * perpendicularDistance;
  const controlPointY = midY + perpDy * perpendicularDistance;

  const controlPoint = { x: controlPointX, y: controlPointY };

  // Return the array of points defining the start point, control point, and end point
  return [startPoint, controlPoint, endPoint];
};
