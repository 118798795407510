import { api } from 'lib/apis';
import { controller } from 'lib/Controller';
import { CraftRecipe, CraftIngredient, ExtraInfo } from '@storyverseco/svs-types';
import { Game } from 'lib/Game';
import { EventListener } from 'lib/EventListener';
import { AppController } from '../Controller';
import { Modals } from 'features/Modals';
import { ModalOpts } from 'features/Modals/ModalController';
import { images } from 'assets/assets';
import { craft as craftApi } from 'lib/apis/craft';
import { CraftMission, RawMissionData } from './CraftMission';

type RecipeData = {
  game: Game;
  gameId: number;
  amount: number;
  availableAmount: number;
  progress: number;
};

export class CraftController extends EventListener {
  missions: number[] = [];
  visibleMissions: number[] = [];
  invisibleMissions: number[] = [];
  private _currentMission: number;
  private _rawMissionsData: Record<number, RawMissionData> = {};
  private _processedMissionData: Record<number, CraftMission> = {};
  private roadmap: number[] = [];
  private _blocked = true;
  private _tmpParentGameId: number;
  private _parentMissions: number[] = [];
  constructor(private app: AppController) {
    super();
    this.events = {
      on_mission_update: [],
    };
  }

  async unblock() {
    if (this._blocked) {
      this._blocked = false;
      await this.fetchMissions();
      this.sendEvents(['on_mission_update']);
    }
  }

  get processed() {
    return this._processedMissionData;
  }

  get rawData() {
    return { ...this._rawMissionsData };
  }

  // existing from BE
  async fetchMissions() {
    if (this._blocked) {
      return;
    }
    const activeMissions = await craftApi.getMissions();
    const missions = activeMissions.map((m) => +m.gameId);

    const raw = activeMissions.reduce((acc, m) => {
      acc[m.gameId] = m;
      return acc;
    }, {});
    this._rawMissionsData = {
      ...this._rawMissionsData,
      ...raw,
    };

    const holdings = controller.user.me.holdings;
    // create processed data
    await Promise.all(
      activeMissions.map(async (m) => {
        delete this.processed[m.gameId];
        const mission = new CraftMission(m, true);
        await mission.preloadRecipeComponent();
        mission.setProgress(holdings);
        this.processed[m.gameId] = mission;
      }),
    );
    this.missions = missions;
    this.visibleMissions = missions.slice(0, 2);
    this.invisibleMissions = missions.slice(2);
    this.sendEvents(['on_mission_update']);
  }

  async fetchInventory() {
    const currentUser = controller.user.me;
    const userName = currentUser?.username;
    const profileData = await api.user.getUserHoldingsByUsername(userName, true);
    return profileData;
  }

  setMission(gameId: number) {
    this._currentMission = gameId;
  }

  get currentMission() {
    return this._currentMission;
  }

  get currentMissionRoadmap() {
    return [...this.roadmap];
  }

  isNewMissionPartOfRecipe(gameId: number) {
    return this.roadmap.includes(gameId);
  }

  async showMissionInfo(gameId: number, opts: ModalOpts = {}) {
    const dataItemId = opts.data?.item?.id;
    const id = dataItemId || gameId;

    const referrer = opts.data?.referrer;
    // we are in mission and one ingredient is craftable
    // we need to create a chain
    if (this.inMission && this.isNewMissionPartOfRecipe(id)) {
      this._tmpParentGameId = this._currentMission;
    }
    this.setMission(id);
    // no data for this mission
    // show preloader?
    if (!this.processed[id]) {
      return;
    }

    controller.modals.open(Modals.DrawerCraftMake, opts);
    // only sort if it's an existing mission
    if (this.missions.indexOf(id) > 1) {
      this.updateSortedMissions(id);
    }
  }

  async updateSortedMissions(gameId: number) {
    const missions = await craftApi.updateMission(gameId);
    // just re-sort
    this.visibleMissions = missions.splice(0, 2);
    this.invisibleMissions = missions.splice(2);
    this.sendEvents(['on_mission_update']);
  }

  getMissionData(gameId?: number) {
    const id = gameId || this._currentMission;
    if (!id || !this.processed[id]) {
      // should never be there
      console.log('Craft mission details: no details for', id);
      return;
    }
    const data = this.processed[id];
    return data;
  }

  async completeMission(gameId: number) {
    this.sendEvents(['on_mission_update']);
    this.missions = this.missions.splice(this.missions.indexOf(gameId), 1);

    // if no recipe, it will be updated from feed info
    // with fresh data
    const mission = this._rawMissionsData[gameId];
    delete mission.recipe;

    await craftApi.completeMission(gameId);
    await this.updateMissionsData();
  }

  get inMission() {
    return !!this.roadmap.length;
  }

  get hasParentMission() {
    return !!this._parentMissions.length;
  }

  restoreParentMissionState() {
    if (this.hasParentMission) {
      this._currentMission = this._parentMissions.pop();
      this.startMissionFeed();
    }
  }

  getNextFeedItem(gameId: number) {
    const index = this.roadmap.findIndex((id) => +id === +gameId);
    if (index === -1) {
      return;
    }
    return this.roadmap[index + 1];
  }

  async goToNextIngredientGame(currentGameId: number) {
    const nextGameId = this.getNextFeedItem(currentGameId);
    // await this.preloadRecipeComponent();
    if (!nextGameId) {
      this.roadmap = [];
      // load parent game
      controller.feed.loadTempItem(this._currentMission);
      return;
    }
    controller.feed.loadTempItem(nextGameId);
  }

  async loadMissionData(gameId: number) {
    if (this.processed[gameId]) {
      return;
    }
    this._rawMissionsData[gameId] = { gameId };
    const mission = new CraftMission({ gameId }, true);
    this._processedMissionData[gameId] = mission;
  }

  updateRawMissionData(gameId: number, data: ExtraInfo) {
    const rawMission = this._rawMissionsData[gameId];
    if (rawMission?.recipe) {
      return;
    }
    const missionData = {
      gameId: data.recipe.gameId,
      recipe: data.recipe,
    };
    this._rawMissionsData[gameId] = missionData;
    // this game is from extraUI and it is already in feed
    // all data is already cached
    this.processed[gameId] = new CraftMission(missionData);
  }

  getGameExtraInfoRecipeProgress(gameId: number) {
    const holdings = controller.user.me.holdings;
    const mission = this._rawMissionsData[gameId];
    console.log('getGameExtraInfoRecipeProgress', gameId, this._rawMissionsData);
    if (!mission) {
      console.log('No data for', gameId, holdings);
      return;
    }
    const recipe = mission.recipe;
    const totalIngredients = recipe.ingredients.length;

    const recipeProgress = recipe.ingredients.reduce((count, ingredient) => {
      const holding = holdings.find((item) => item.offChainGameId === ingredient.gameId);
      return count + (holding ? 1 : 0);
    }, 0);

    return `${Math.min(recipeProgress, totalIngredients)} of ${totalIngredients}`;
  }

  async startMissionFeed() {
    // fresh one
    const mission = this._currentMission;
    if (this.missions.indexOf(mission) === -1) {
      await craftApi.startMission(mission);
      this.updateSortedMissions(mission);
    }

    // create chain of parents
    if (this._tmpParentGameId) {
      this._parentMissions.push(this._tmpParentGameId);
      this._tmpParentGameId = null;
    }

    const missionData = this.processed[mission];
    const gamesToLoad = missionData.recipeUIData.filter((recipeData) => recipeData.progress < 100).map((recipeData) => +recipeData.gameId);
    this.roadmap = gamesToLoad;

    const ingredientGameId = gamesToLoad[0];
    controller.feed.loadTempItem(ingredientGameId);
  }

  async updateMissionsData() {
    await controller.user.fetchHoldings();
    await this.fetchMissions();

    this.updateProcessedMissionsHoldings();

    controller.feed.updateCraftableGamesIngredientList();
  }

  async updateProcessedMissionsHoldings() {
    const holdings = controller.user.me.holdings;
    Object.values(this.processed).forEach((mission) => {
      mission.setProgress(holdings);
    });
  }

  showGameOutcomeModal(currentItem: Game, opts: ModalOpts = {}) {
    if (currentItem.gem.type === 'craftable') {
      this.showMissionInfo(currentItem.id, opts);
    } else {
      controller.modals.open(Modals.GameOutcomeWin, opts);
    }
  }
}
