import type { AppController } from '../Controller';
import { EventListener } from '../EventListener';
import { navbar } from 'lib/navbarSuite';

export class UserInputController extends EventListener {
  private clickPositionX: number;
  private clickPositionY: number;

  public get clickPosition() {
    return { x: this.clickPositionX, y: this.clickPositionY };
  }

  constructor(private app: AppController) {
    super();
    this.init();
  }

  public init() {
    this.resetPosition();
    this.addEventListeners();
  }

  private addEventListeners() {
    this.removeEventListeners();
    window.addEventListener('click', this.onWindowClick);
    navbar.on('STORY_TAP', this.onStoryTap);
  }

  private removeEventListeners() {
    window.removeEventListener('click', this.onWindowClick);
    navbar.off('STORY_TAP', this.onStoryTap);
  }

  private resetPosition() {
    this.clickPositionX = 0;
    this.clickPositionY = 0;
  }

  private getOffsetY() {
    const element = document.getElementById('story-game');
    if (element) {
      const rect = element.getBoundingClientRect();
      return rect.top;
    } else {
      return 0;
    }
  }

  private onStoryTap = (opts) => {
    this.clickPositionX = opts.coordinates.x;
    this.clickPositionY = opts.coordinates.y + this.getOffsetY();
    console.log('UserInputController onStoryTap', this.clickPosition);
  };

  private onWindowClick = (event) => {
    this.clickPositionX = event.clientX;
    this.clickPositionY = event.clientY;
    console.log('UserInputController onWindowClick', this.clickPosition);
  };
}
