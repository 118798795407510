import { ActivityData, ActivityType as BaseActivityType } from '@storyverseco/svs-types';
export { ActivityType } from '@storyverseco/svs-types';

export interface ActivityItem extends ActivityData {
  gemType: 'coin' | 'diamond'; // normally just any string, but loosely enforcing these string types
  groupId?: string;
  items?: ActivityItem[];
}

export interface ActivityFeedItem extends ActivityItem {
  unread: boolean;
}

export const activityTypes = Object.values(BaseActivityType).filter((value) => typeof value === 'string');

export enum FEOnlyActivityType {
  UpdateAvailable = 'fe_update_available',
}
